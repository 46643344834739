// quickshop inline
.quickshop-inline-top {
  @include rotate(45deg);
  @include box-shadow(4px 3px 5px 0 rgba(0, 0, 0, 0.3));
  position: absolute;
  top: -10px;
  #{$ldirection}: 25%; //or 75%
  width: 20px;
  height: 20px;
  background: $color-white;
  #{$renutriv} & {
    @include box-shadow(4px 3px 5px 0 rgba(255, 255, 255, 0.3));
  }
  .quickshop-inline--second & {
    #{$ldirection}: 75%;
  }
}

.quickshop-inline {
  @include box-shadow(inset 0px -8px 4px -4px rgba(0, 0, 0, 0.3));
  @include swap_direction(padding, 35px $mobile-outer-gutter 35px $mobile-outer-gutter);
  margin-bottom: 50px;
  #{$renutriv} & {
    @include box-shadow(inset 0px -8px 4px -4px rgba(255, 255, 255, 0.3));
  }
  select {
    clear: both;
    width: 100%;
  }
  #{$estee_edit} & {
    text-align: center;
    background-color: $color-white;
    margin-top: 7px;
  }
}

.quickshop-inline__attribute {
  text-align: center;
}

.quickshop-inline__attribute-header {
  @include text--bold;
  text-transform: uppercase;
  #{$renutriv} & {
    text-transform: uppercase;
    font-family: $roboto-condensed;
    font-size: 14px;
    letter-spacing: normal;
  }
}

.quickshop-inline__attribute-text {
  @include headline--quinary;
  line-height: 27px;
  @include swap_direction(margin, 5px 0 17px 0);
  #{$renutriv} & {
    font-family: $roboto-condensed;
  }
  #{$estee_edit} & {
    font-family: $ffmarkweb;
    text-transform: uppercase;
    font-size: 15px;
  }
}

.quickshop-inline__add-button,
.quickshop-inline__phone-button {
  margin-top: 10px;
  height: 45px;
  line-height: 43px;
  width: 100%;
  #{$renutriv} & {
    font-family: $roboto-condensed;
  }
}

.quickshop-inline__phone-button-icon {
  background-size: auto auto;
  background-position: 0 -6901px;
  display: inline-block;
  height: 18px;
  line-height: 18px;
  padding-#{$ldirection}: 20px;
  margin-top: 12px;
  #{$renutriv} & {
    background: image-url('../themes/estee_base/img/sprites/order_phone_at2x--white.png') no-repeat left center;
    background-size: contain;
  }
  #{$estee_edit} & {
    background: image-url('../themes/estee_base/img/sprites/order_phone_at2x--white.png') no-repeat left center;
    background-size: contain;
  }
}

.quickshop-inline__wishlist,
.quickshop-inline__details {
  display: block;
  margin-top: 18px;
  text-align: center;
  #{$link} {
    @include link--bold;
    font-size: 12px;
    text-decoration: underline;
    text-transform: uppercase;
    letter-spacing: 0.15em;
  }
  #{$renutriv} & {
    font-family: $roboto-condensed;
  }
  #{$estee_edit} & {
    font-family: $ffmarkweb-medium;
    color: $color-black;
    font-size: 12px;
  }
}

.quickshop-inline__shade-select {
  width: 100%;
  .selectBox-label {
    width: auto;
  }
}

select.quickshop-inline__price-size {
  &.reference {
    display: none;
  }
}

.quickshop-inline__skintype-select {
  margin-top: 10px;
}

.quickshop-inline__shade-select-selectBox-dropdown-menu {
  @include swap_direction(padding, 0);
  li {
    @include swap_direction(padding, 0);
    a {
      @include swap_direction(padding, 0);
      line-height: 43px;
    }
  }
}
