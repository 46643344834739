/* GWP MOBILE LANDING PAGE STYLES

* INCLUDED IN /mobile/promotions/gwp/index.tmpl, as of this writing

*/
body#gwp {
  .gwp-cms-content-container {
    height: 1425px;
    margin-#{$ldirection}: 0px;
    margin-top: 0px;
  }
  .gwp_header_logo_image {
    /* position:relative; */
    /* width:320px; */
    /* border-top: 1px solid #BBBAC0; */
    position: absolute;
    z-index: 5;
    top: 136px;
    #{$ldirection}: 20px;
  }
  .gwp_header_image {
    /* position:relative; */
    /* width:320px; */
    /* border-top: 1px solid #BBBAC0; */
    position: absolute;
    z-index: 4;
    top: 116px;
  }
  .limited_edition_title_image {
    z-index: 100;
    margin-top: 537px;
    margin-#{$ldirection}: 6%;
  }
  .title_image {
    z-index: 100;
    margin-top: 20px;
    margin-#{$ldirection}: 6%;
  }
  .gwp_product_wrapper {
    margin-top: 25px;
  }
  .gwp_mobile_product {
    position: relative;
    width: 50%;
    z-index: 3;
  }
  .gwp_mobile_product.left {
    float: $ldirection;
  }
  .gwp_mobile_product.right {
    float: $rdirection;
  }
  .mobile_product_image {
    position: relative;
    width: 100%;
    z-index: 2;
    margin-top: 50px;
  }
  .mobile_product_image1 {
    margin-top: 50px;
    margin-#{$ldirection}: 10px;
  }
  .mobile_product_image2 {
    margin-top: 50px;
    margin-#{$ldirection}: 10px;
  }
  .mobile_product_checkbox {
    margin-#{$ldirection}: 25px;
    margin-top: 10%;
    z-index: 100001;
    letter-spacing: 0.01px;
  }
  .mobile_product_checkbox2 {
    top: 15px;
  }
  .mobile_checkbox_label_image {
    position: absolute;
    #{$ldirection}: 67px;
  }
  .mobile_product_see_more_link {
    margin-#{$ldirection}: 34px;
    margin-top: 71px;
  }
  .mobile_product_title_image_1 {
  }
  .mobile_product_title_image_2 {
  }
  .mobile_product_title_image_3 {
    float: $rdirection;
    padding-#{$rdirection}: 35px;
    padding-top: 0px;
    margin-top: 9px;
  }
  .mobile_product_title_image_sold_out {
    padding-#{$rdirection}: 63px;
  }
  .mobile_product_title_image_2.mobile_product_title_image_sold_out {
    padding-#{$rdirection}: 69px;
  }
  .mobile_product_see_more_link_sold_out {
    #{$ldirection}: 132px;
  }
  #choiceError.mobile {
    font-size: 0.917em;
    margin-#{$ldirection}: 26%;
    margin-#{$rdirection}: 26%;
    position: absolute;
    top: -41px;
    width: 190px;
  }
  .mobile_see_more_link_text {
    letter-spacing: 0.02em;
    color: #000000;
    text-transform: none;
    font-size: 12px;
    font-weight: bold;
    text-decoration: underline;
  }
  .mobile_additional_image {
    position: relative;
    width: 320px;
  }
  .disclaimer_copy {
    position: relative;
    #{$ldirection}: 5px;
    z-index: 20;
    @include swap_direction(padding, 31px 10px 20px);
    width: 300px;
    font-size: 0.917em;
    color: #999999;
    line-height: 11px;
    border-top: 1px solid #999999;
  }
  .lower-gwp-container {
    border-bottom: none;
    height: 108px;
    position: relative;
    #{$ldirection}: 0px;
    width: 320px;
    z-index: 18;
    top: 651px;
  }
  #gwp-buttoncontinuecheck.gwp-mobile-buttoncontinuecheck {
    margin-#{$ldirection}: 10%;
    margin-#{$rdirection}: 10%;
    margin-top: -96px;
    position: relative;
    z-index: 144;
  }
  .no_offer_text {
    /*
    color: #797979;
    font-size: 16px;
    margin-#{$ldirection}: 10px;
    margin-top: 20px;
    */
    color: #ffffff;
    font-size: 11px;
    position: absolute;
    top: 289px;
    #{$ldirection}: 10px;
  }
  .additional_text {
    margin-#{$ldirection}: 16px;
    margin-top: 7px;
    font-size: 13px;
    padding-#{$rdirection}: 10px;
    color: #797979;
    padding-bottom: 22px;
  }
  .slideshow_wrapper {
    border-top: 1px solid #bbbac2;
    margin-top: 28px;
    padding-top: 1px;
    height: 306px;
    z-index: 100000;
    width: 320px;
  }
  .mobile_sold_out_text {
    position: absolute;
    top: 174px;
    #{$ldirection}: 146px;
    display: none;
  }
  #home #slide-show #slide-dots {
    position: absolute;
    #{$rdirection}: 5px;
    top: 291px;
  }
  /* to specify the slideshow image height */
  #feature-content #touchCarousel #wrapper {
    height: 306px;
  }
  #touchCarousel ul#slide-dot-container {
    bottom: -91px;
    #{$rdirection}: -40px;
  }
  .additional_image {
    margin-top: 0px;
    z-index: 1;
    position: absolute;
    top: 941px;
  }
  .footer_image {
    z-index: 100;
    margin-top: -12px;
    margin-#{$ldirection}: 0px;
  }
  .gwp-cms-content-container input[type='checkbox'] ~ label:before,
  .gwp-cms-content-container ~ .label:before {
    height: 20px;
    width: 20px;
  }
  .gwp-cms-content-container input[type='checkbox'] ~ label:after,
  .gwp-cms-content-container ~ .label:after {
    height: 20px;
    width: 20px;
    background-position: center;
  }
  .gwp_mobile_sold_out_text {
    #{$ldirection}: -5px;
    position: absolute;
    top: 190px;
    width: 143px;
  }
  .gwp_opaque {
    opacity: 1;
  }
}
