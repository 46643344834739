.spp-howtouse__header {
  @include headline--quaternary;
  padding-bottom: 6px;
  border-bottom: 1px solid $color-light-gray;
  @include swap_direction(margin, 0 8% 38px 8%);
  #{$aerin} & {
    border-color: $color-periwinkle-blue--aerin;
  }
  #{$renutriv} & {
    border-color: $color-gold--renutriv;
    padding-bottom: 2px;
    font-family: $bb-roman;
    text-transform: uppercase;
    font-size: 35px;
    letter-spacing: 0.03em;
    font-weight: lighter;
  }
  @media #{$large-up} {
    width: 54%;
    margin-#{$rdirection}: 0;
  }
}

.spp-howtouse__products {
  @include clearfix;
  margin-bottom: 40px;
  .spp-howtouse__product {
    max-width: 132px;
    text-align: center;
    &:nth-of-type(2n + 1) {
      float: $ldirection;
      clear: both;
    }
    &:nth-of-type(2n + 2) {
      float: $rdirection;
      clear: $rdirection;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .spp-howtouse__product-image {
    max-width: 100%;
  }
  .text-product-listing-names-main,
  .text-product-listing-names-sub {
    margin-bottom: 10px;
  }
  .button__quickshop {
    margin-top: 10px;
  }
  .headline--secondary {
    margin-bottom: 10px;
  }
  .text-product-listing-names-main,
  .text-product-listing-names-sub {
    margin-bottom: 10px;
  }
  .spp_howtouse__product {
    .sku-brief__button {
      margin-top: 10px;
    }
  }
}
