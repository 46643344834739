///
/// @file esteelauder_custom_brand/_aerin_tout_header_mobile.scss
///
/// \brief styles for template field module at:
///
///  esteelauder_custom_brand/templates/aerin_tout_header_mobile_v1
///

.aerin-header-tout {
  @include swap_direction(margin, 0 auto);
  max-width: 640px;
}

.aerin-header-tout__img {
  max-width: 100%;
}

#aerinmppIframe {
  @media #{$iphone6-landscape} {
    width: 667px;
    height: 919px;
    //width:375px;
    //height:627px;
    //margin-#{$ldirection}: 22%;
    display: block;
  }
}
