///
/// @file drupal_modules/esteelauder_common/_video_youtube_mobile_double.scss
/// Styles for promo item linking to a youtube video and then another youtube video that plays after the first one.
///
/// \brief Styling for template at:
///   esteelauder/modules/custom/esteelauder_common/templates/video_youtube_mobile_double_v1
///

.video_youtube_double {
  position: relative;
  @include swap_direction(margin, 0 auto);
  .video-headline-mobile {
    position: relative;
    text-align: center;
    width: 100%;
    font-size: 60px;
  }
  .video_youtube_double__content {
    &.video_second {
      display: none;
    }
    min-height: 119px;
    width: 100%;
    @include swap_direction(margin, 0);
    color: #fff;
    position: relative;
    .video-play {
      position: absolute;
      top: 30%;
      #{$ldirection}: 45%;
      z-index: 2;
    }
    .video-title {
      position: absolute;
      #{$ldirection}: 0;
      bottom: 5px;
      width: 100%;
      text-align: center;
      span {
        display: inline-block;
        font-size: 14px;
        font-family: 'AkzidenzGrotesk W1G';
      }
    }
  }
  .video-bg-mobile {
    max-width: 100%;
  }
  .video-play {
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30px 0 30px 50px;
    border-color: transparent transparent transparent $color-white;
  }
  footer {
    padding-top: 20px;
    text-align: center;
    .text-promo-header,
    .text-promo {
      font-size: 14px;
      color: #000;
      display: inline-block;
    }
    .text-promo-header {
      font-weight: bold;
    }
    .text-promo {
      font-weight: normal;
    }
  }
}

// pure color envy specific css
.section-pure-color-envy.device-mobile {
  .node-2836 {
    .product-brief__price {
      display: none;
    }
    .button__quickshop {
      display: none;
    }
    .mpp__header {
      padding-top: 15px;
    }
  }
}
