///
/// @file drupal_modules/esteelauder_common/_chat_vba_mobile.scss
///

.chat-vba-mobile {
  background: $color-light-gray-blue;
  @include swap_direction(padding, $mobile-outer-gutter);
  #{$renutriv} & {
    background: black;
  }
}
