///
/// Mobile-only homepage bricks
///
.homepage-bricks {
  max-width: 320px;
  @include swap_direction(margin, 0 auto);
  overflow-x: hidden;
}
#{$landing} .responsive-carousel {
  margin-top: 40px;
}

.device-mobile #colorbox {
  &:not(.overlay--interstitial)  {
    .pop_up_interstitial {
      @include swap_direction(border, 1px solid $color-lighter-grey-white);
      text-align: center;
      .pop-up-shop {
        @include swap_direction(padding, 8% 5%);
        &.pop-up-shop-en {
          border-bottom: 1px solid $color-lighter-grey-white;
          p {
            @include swap_direction(margin, 4% 0 5% 0);
          }
        }
        p {
          @include swap_direction(margin, 1% 0 5% 0);
          font-size: 13px;
        }
        @media #{$small-only} and (orientation: landscape) {
          @include swap_direction(padding, 4%);
          &.pop-up-shop-en {
            @include swap_direction(padding, 4%);
          }
          p {
            font-size: 18px;
          }
        }
        a {
          display: inline-block;
          img {
            vertical-align: middle;
            width: 80%;
            height: 100%;
          }
        }
      }
    }
    #cboxClose {
      #{$rdirection}: 35px;
      top: 35px;
      background-position: -4px -977px;
      height: 20px;
      width: 20px;
    }
    #cboxClose:hover {
      #{$rdirection}: 35px;
      top: 35px;
      background-position: -4px -977px;
      height: 20px;
      width: 20px;
    }
    &.add_cart_response {
      #cboxClose {
        top: 10px;
        #{$rdirection}: 15px;
        background-position: -2px -975px;
      }
    }
  }
}

