///
/// @file drupal_modules/esteelauder_common/_video_local_mobile.scss
/// Styles for mobile local video template
///
/// \brief Styling for template at:
///   esteelauder/modules/custom/esteelauder_common/templates/video_local_mobile_v1
///

.video_local_mobile {
  &.style-custom {
    margin-top: 30px;
    margin-bottom: 0;
    .video-local-mobile-headline {
      @include swap_direction(margin, -25px 0 -3px);
      @include swap_direction(padding, 0);
      text-align: center;
      font-size: 65px;
      letter-spacing: -0.07em;
      position: relative;
      z-index: 99;
      line-height: 60px;
    }
    #video-local-container {
      width: 100%;
      line-height: 0;
      position: relative;
    }
    .video-local-title-wrapper {
      position: absolute;
      bottom: 10%;
      display: inline-block;
      width: 100%;
      text-align: center;
      line-height: 20px;
      .video-local-title {
        color: #fff;
        font-weight: normal;
        display: inline-block;
        @include swap_direction(margin, 0 auto);
        text-align: center;
      }
    }
  }
  &.style-title-below-thumbnail {
    .video-local-mobile-headline {
      @include swap_direction(margin, -20px 0 -10px);
    }
    .video-local-title-wrapper {
      position: static;
      margin-bottom: 20px;
      .video-local-title {
        font-weight: normal;
        color: #0f112a;
      }
    }
  }
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
  .video-local-mobile-eyebrow {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 15px;
  }
  .video-local-mobile-headline {
    @include swap_direction(margin, -20px 0 -3px);
    @include swap_direction(padding, 0);
    text-align: center;
    font-size: 60px;
    letter-spacing: -0.05em;
    position: relative;
    z-index: 99;
    line-height: 60px;
  }
  #video-local-container {
    width: 100%;
    position: relative;
  }
  #video-local-thumbnail {
    width: 100%;
  }
  .video-local-title-wrapper {
    position: absolute;
    bottom: 10%;
    display: inline-block;
    width: 100%;
    text-align: center;
    .video-local-title {
      color: #fff;
      font-weight: bold;
      display: inline-block;
      @include swap_direction(margin, 0 auto);
      text-align: center;
    }
  }
}

// iphone 6
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .video_local_mobile {
    &.style-custom {
      margin-top: 30px;
      margin-bottom: 0;
      .video-local-mobile-headline {
        @include swap_direction(margin, -20px 0 0);
        @include swap_direction(padding, 0);
        text-align: center;
        font-size: 75px;
        letter-spacing: -0.07em;
        position: relative;
        z-index: 99;
        line-height: 70px;
      }
      #video-local-container {
        width: 100%;
        line-height: 0;
        position: relative;
      }
      .video-local-title-wrapper {
        position: absolute;
        bottom: 8%;
        display: inline-block;
        width: 100%;
        text-align: center;
        line-height: 20px;
        .video-local-title {
          color: #fff;
          font-weight: normal;
          display: inline-block;
          @include swap_direction(margin, 0 auto);
          text-align: center;
          font-size: 14px;
          letter-spacing: 0.15em;
        }
      }
    }
  }
}
