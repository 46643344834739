.livechat_content {
  width: 100%;
  font-family: $akzidenz;
  font-size: 0.75em;
  letter-spacing: 0.05em;
  @include leading(21px);
  @include swap_direction(margin, 0px auto);
  #live_chat_header {
    border-bottom: 1px solid $color-light-gray;
    height: 55px;
    @include swap_direction(margin, 0);
    @include swap_direction(margin, 40px 0 10px);
    text-align: center;
    h3 {
      font-weight: normal;
    }
  }
  .chat_product_queries,
  .chat_technical_queries {
    text-align: center;
  }
  .chat_continue_shopping a,
  .goback_link a,
  a.close_chat {
    text-decoration: underline;
    font-weight: bold;
    font-size: 1.1em;
  }
  .chat_continue_shopping {
    text-align: center;
    @include swap_direction(padding, 10px 0 30px 0);
  }
  .goback_link,
  #close_chat {
    text-align: center;
    @include swap_direction(padding, 0px 0 30px 0);
  }
  p.chat_time {
    @include swap_direction(margin, 0px);
    font-size: 0.96em;
  }
  p.chat_beauty_tips {
    width: 80%;
    @include swap_direction(margin, 0 auto);
    letter-spacing: 0.15em;
    text-transform: uppercase;
    text-align: center;
    font-size: 1em;
  }
  span.email_form input[type='checkbox'] {
    position: static;
  }
  span.chat_now_h {
    @include leading(44px);
    background: none repeat scroll 0 0 $color-navy;
    @include swap_direction(border, medium none);
    @include border-radius(0);
    color: $color-white;
    cursor: pointer;
    display: inline-block;
    font-family: $akzidenz;
    font-weight: bold;
    height: 44px;
    letter-spacing: 0.15em;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    width: 80%;
    @include swap_direction(margin, 10px 0 10px 0);
  }
  p.or_text {
    font-weight: bold;
    text-align: center;
    font-size: 0.96em;
    @include swap_direction(margin, 2% 0 0);
  }
  #live_beauty_tips {
    text-align: center;
    @include swap_direction(padding, 10px 0 0 0);
  }
  input[type='text'],
  input[type='submit'],
  textarea {
    width: 80%;
  }
  #contact_form_fields input,
  textarea {
    @include swap_direction(margin, 5% 0 0 10%);
  }
  #contact_form_fields input:nth-child(2) {
    @include swap_direction(margin, 0 0 0 10%);
  }
  #chat_window {
    width: 80%;
    @include swap_direction(margin, 5% 0 0 10%);
    text-align: $ldirection;
    @include swap_direction(border, 1px solid $color-black);
    margin-bottom: 15px;
    @include swap_direction(padding, 12px 12px 12px 10px);
  }
  .email_chat,
  p.chat_thanks_txt,
  p.chat_unavailable {
    @include swap_direction(margin, 0 auto);
    letter-spacing: 0.1em;
    font-size: 1em;
    width: 80%;
  }
  .email_chat p,
  .live_chat_text p,
  .sorry_livechat_text p {
    letter-spacing: 0.09em;
    font-size: 1em;
  }
  .chat_buttons,
  .sorry_livechat_text {
    width: 100%;
    text-align: center;
    @include swap_direction(margin, 10px 0);
  }
  .live_chat_text {
    width: 100%;
    text-align: center;
    @include swap_direction(margin, 10px 0 20px 0);
  }
  .chat_buttons .form-submit,
  .live_chat_text .form-submit {
    @include swap_direction(margin, 10px 0);
    width: 80%;
    text-align: center;
  }
  a.cart_acount,
  a.chat_email,
  a.tele_number {
    text-decoration: underline;
  }
  p.chat_rate_txt {
    font-weight: bold;
    text-align: center;
    font-size: 0.75em;
  }
  p.chat_thankyou_txt,
  p.chat_sorry_txt {
    text-align: justify;
    @include swap_direction(margin, 2% 0 0 10%);
    width: 80%;
    letter-spacing: 0.1em;
    font-size: 1em;
  }
  .error_messages {
    @include swap_direction(margin, 0 0 0 10%);
  }
  #chat_container,
  #chat_ended,
  #chat_unavailable {
    display: none;
  }
  .agent_typing {
    display: none;
    color: $color-black;
    @include swap_direction(margin, 0 0 0 0%);
  }
  #chat_window .server span {
    font-weight: normal;
    color: $color-black;
    font-size: 1em;
  }
  #chat_window .client span {
    font-weight: normal;
    font-size: 1em;
    color: $color-darker-gray;
  }
}

.cart-dialog {
  background: none repeat scroll 0 0 $color-white;
  @include swap_direction(border, 1px solid $color-black);
  color: $color-white;
  font-size: 0.75em;
  font-weight: bold;
  line-height: 20px;
  @include swap_direction(padding, 10px 10px 30px);
  position: absolute;
  text-align: center;
  width: 300px;
  z-index: 1000;
}

.cart-dialog img {
  @include swap_direction(border-radius, 4px 4px 4px 4px);
}

.cart-dialog p {
  margin-bottom: 16px;
  color: $color-black;
}

.cart-dialog .block-button {
  background: none;
  display: block;
  margin-#{$rdirection}: 35px;
  height: 32px;
  width: 100px;
  input {
    width: 120px;
    @include swap_direction(padding, 0 10px);
  }
}

.cart-dialog .checkout-btn {
  float: $rdirection;
  @include swap_direction(margin, 0);
}

.cart-dialog .continue-button {
  background: none;
  display: block;
  height: 34px;
  margin-#{$ldirection}: 10px;
  width: 100px;
  input {
    width: 120px;
    @include swap_direction(padding, 0 10px);
  }
}

.overlay {
  background: none repeat scroll 0 0 $color-black;
  height: 100%;
  #{$ldirection}: 0;
  opacity: 0.5;
  position: absolute;
  text-align: center;
  top: 0;
  visibility: visible;
  width: 100%;
  z-index: 1000;
}

.bb-cart-dialog {
  background: none repeat scroll 0 0 $color-white;
  @include swap_direction(border, 5px solid $color-white);
  color: $color-black;
  margin-bottom: 15px;
}

.bb-cart-dialog .bb-dialog-close {
  cursor: pointer;
  float: $rdirection;
  padding-bottom: 50px;
  padding-#{$ldirection}: 30px;
}

.bb-checkout-btn {
  @include swap_direction(margin, 5px 0 0);
}

.bb-cart-dialog .header {
  color: $color-black;
  font-size: 0.9em;
  font-weight: bold;
  text-transform: uppercase;
}
