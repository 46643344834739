.mpp {
  padding-bottom: 20px;
  @include swap_direction(margin, 0 auto);
  width: 100%;
  &.product-heading-mpp {
    .mpp__product-grid {
      .left + li {
        margin-left: 0;
      }
    }
  }
}

.mpp__header {
  @include headline--products;
  text-align: center;
  padding-top: 20px;
}

.mpp__product-grid {
  @include pie-clearfix;
  @include opacity(0);
  @include opacityTransition(0.1s);
  margin-top: 20px;
  .product-brief {
    @include swap_direction(margin, 0 6.25% 33px 6.25%);
    &.product-brief--second {
      clear: $rdirection;
      @include swap_direction(margin, 0 6.25% 33px 6.25%);
    }
    &__quickshop-container {
      li.shade-picker__swatches > a.selected {
        @include swap_direction(border, 1px solid $color-dark-navy);
      }
    }
    &.product-brief-tout {
      width: 100%;
      margin: 0;
      .module_block__hero {
        position: relative;
        .module_block__imgs {
          width: 100%;
        }
      }
      .video_content_cta_outer {
        bottom: 10px;
        #{$ldirection}: 10px;
      }
      .product-tout__title {
        text-transform: capitalize;
        font-size: 48px;
        padding-top: 7px;
        margin: 0;
        font-family: $optimalight;
        letter-spacing: -0.005em;
        color: $color-navy;
      }
      .product-tout__eyebrow {
        margin-top: 0;
        padding-top: 20px;
        font-size: 12px;
        letter-spacing: 0.15em;
        font-family: $akzidenz;
      }
      .product-tout {
        &__copy--bold {
          font-weight: bold;
        }
        &__copy,
        &__copy--bold {
          margin-top: 2px;
          font-size: 15px;
          letter-spacing: 0.05em;
          font-family: $akzidenz;
        }
        &__uppercase {
          text-transform: uppercase;
          p {
            font-size: 12px;
          }
        }
        &__uppercase--bold {
          font-weight: bold;
          text-transform: uppercase;
          p {
            font-size: 12px;
          }
        }
      }
      .product-tout__cta {
        margin-top: 20px;
        &--text {
          display: inline-block;
          background: transparent;
          font-size: 12px;
          font-family: $akzidenz;
          letter-spacing: 0.15em;
          text-transform: uppercase;
          cursor: pointer;
          font-weight: bold;
          margin-bottom: 20px;
        }
        &--cta {
          @include background($color-navy);
          color: $color-white;
          height: 40px;
          line-height: 40px;
          width: 70%;
          padding: 0 20px;
        }
        &--link {
          color: $color-navy;
          text-decoration: underline;
          &.product-tout__cta--dark {
            background: transparent;
            color: $color-navy;
          }
        }
        &--dark {
          @include background($color-navy);
          color: $color-white;
        }
        &--light {
          @include background($color-periwinkle-blue);
          color: $color-white;
        }
        &--transparent-light {
          border: 1px solid $color-white;
          color: $color-white;
          background: transparent;
        }
        &--transparent-dark {
          border: 1px solid $color-navy;
          color: $color-navy;
          background: transparent;
        }
      }
      .product-tout__img {
        opacity: 1;
        width: 100%;
        &.product-tout__large {
          display: none;
        }
      }
      .product-tout__text--dark {
        position: relative;
        padding: 0;
      }
      .product-tout--flexi-height-mobile {
        .product-tout__text {
          &--navy * {
            .product-tout {
              &__title,
              &__copy {
                color: $color-navy;
              }
            }
          }
          &--white * {
            .product-tout {
              &__title,
              &__copy {
                color: $color-white;
              }
            }
          }
        }
      }
      .product-tout--flexi-height-mobile {
        .product-tout__text {
          position: absolute;
          top: 0;
          padding: 0;
          width: 100%;
          &--center {
            top: 20%;
          }
          &--bottom {
            top: auto;
            bottom: 0;
          }
        }
      }
      .autoplay_video {
        .looping_vidContent {
          position: absolute;
          z-index: 1;
          width: 100%;
          height: 100%;
          display: block;
          opacity: 0;
        }
        button {
          background: none;
          border: none;
          padding: 0;
          margin: 0;
          cursor: pointer;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
        }
      }
      .fadeElemIn {
        @include animationDuration(0.8s);
        @include animation-name(fadeElemIn);
      }
      .fadeElemOut {
        @include animationDuration(0.8s);
        @include animation-name(fadeElemOut);
        .module_block__img {
          height: 240px;
        }
      }
      .module_block__imgs .module_block__img.desktopImgSrsSet,
      .module_block__imgs .module_block__img.desktopMobImgSrsSet {
        height: 36.631vw;
        text-indent: -100vw;
      }
      .autoplay_video.mob_auto_loop {
        display: none;
      }
    }
  }
  &.grid-fade-in {
    @include opacity(1);
  }
}

.mpp__product-sort {
  width: 87%;
  @include swap_direction(margin, 10px 6.25% 0 6.25%);
}

// custom title for node 19872
.node-19872 {
  h2.mpp__header {
    padding-#{$ldirection}: 15%;
    padding-#{$rdirection}: 15%;
  }
}

.field-mobile-template {
  .mpp {
    h2.mpp__header {
      margin-top: 60px;
    }
  }
}

.content {
  & > .field-mobile-template {
    & :first-child.mpp {
      h2.mpp__header {
        margin-top: 0px;
      }
    }
  }
}

// custom adjustments for node 29282
.node-29282 {
  .node-29910 {
    .hero-tout-mobile__content--a {
      margin-top: 0;
      min-height: 315px;
    }
    .hero-tout-mobile__hero--a {
      top: 5px;
    }
  }
}

article.node-26026,
article.node-29190 {
  .field-mobile-template {
    .mpp {
      h2.mpp__header {
        margin-top: 0px;
      }
    }
    & > div {
      & > img {
        width: 100%;
      }
    }
  }
}

// Temporary hide Sort By on Aerin Holiday MPP
.page-node-38920.brand-aerin.device-mobile {
  .field-mobile-template .mpp h2.mpp__header {
    margin-top: 0;
  }
  .mpp__product-sort-container {
    display: none;
  }
  ul.mpp__product-grid {
    margin-top: 5px;
  }
}
// Estee Edit custom mpp
#{$estee_edit} {
  .mpp__anchor {
    float: left;
    position: relative;
    top: -$header-item-height;
    &:first-child {
      top: -$header-with-searchbar-item-height;
    }
  }
}

.estee_edit_custom {
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  .mpp__product__feature {
    &.right {
      .product-brief {
        float: right;
      }
    }
  }
  .product-brief__headers__container {
    // tallest headline
    min-height: 88px;
  }
  .product-brief__price_review__container {
    //position: relative;
    min-height: 35px;
    width: 100%;
    //bottom: 52px;
    margin-top: 7px;
  }
  .product-brief__price {
    min-height: 15px;
    margin-bottom: 1px;
  }
  .product-brief__reviews {
    min-height: 20px;
    margin-bottom: 7px;
  }
  &.center-top {
    background-position: 0 135px;
    .grid__spacer {
      padding-top: 115%;
    }
  }
  .logo_image {
    position: absolute;
    left: 0;
    top: 250px;
    @media #{$iphone6-landscape} {
      left: 0;
      top: 450px;
    }
    img {
      width: 75%;
    }
  }
  &.main .grid__spacer,
  &.left-bottom .grid__spacer {
    padding-top: 100%;
  }
  .mpp__header_image img {
    width: 100%;
    position: absolute;
  }
  .feature_product_header_copy {
    text-align: center;
    position: absolute;
    top: 0;
    em {
      font-family: $devinne-italic;
      font-style: normal;
    }
    &.white {
      color: $color-white;
    }
    &.right {
      right: 2%;
      @media #{$iphone6-landscape} {
        right: 7%;
      }
      //width: 33%;
    }
    &.main {
      left: 23px;
      top: 123px;
      //width: 50%;
      @media #{$iphone6-landscape} {
        left: 23px;
        top: 323px;
      }
    }
    &.left-bottom {
      left: 23px;
      top: 230px;
      //width: 50%;
      @media #{$iphone6-landscape} {
        left: 23px;
        top: 333px;
      }
      @media #{$iphone6-portrait} {
        top: 275px;
      }
    }
    &.center {
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      @media #{$iphone6-landscape} {
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &.center-top {
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      @media #{$iphone6-landscape} {
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .mpp__header__h2 {
    border: none;
    font-family: $devinne-roman;
    font-size: 50px;
    letter-spacing: 0;
    line-height: 38px;
  }
  .mpp__header__h3 {
    border: none;
    font-family: $devinne-roman;
    font-size: 60px;
    line-height: 0.5em;
  }
  .mpp__header__h5 {
    border: none;
    font-family: $devinne-italic;
    font-size: 50px;
    line-height: 0.9em;
    margin: 3px 0 -18px 0;
    letter-spacing: 0;
  }
  .mpp__header__h4 {
    border: none;
    font-family: $devinne-roman;
    font-size: 20px;
    //margin: 33px 0;
    line-height: 20px;
    margin-top: 5px;
  }
  .mpp__header__secondary__h4 {
    border: none;
    font-family: $devinne-italic;
    font-size: 20px;
    margin: -19px 0 0 0;
    text-align: center;
  }
}

.renutriv-pink-images {
  .page-wrapper {
    padding-top: 52px;
  }
  .boutique {
    &#re-nutriv-alchemy_container {
      @include swap_direction(padding, 0);
    }
  }
}
