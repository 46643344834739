///
/// @file drupal_modules/esteelauder_common/_video_local_stacked_mobile.scss
/// Styles for local stacked videos template
///
/// \brief Styling for template at:
///   esteelauder/modules/custom/esteelauder_common/templates/video_local_stacked_mobile_v1
///

.video_local-stacked {
  width: 100%;
  margin-top: -1px;
  margin-bottom: -60px;
  video::-webkit-media-controls {
    //opacity: 0;
  }
  .free-shipping {
    font-family: 'AkzidenzGrotesk W1G';
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.17em;
    color: #9eafff;
    text-transform: uppercase;
    background: #040a2b;
    height: 37px;
    line-height: 37px;
    @include swap_direction(padding, 0 0 0 14px);
    width: 100%;
    text-align: center;
  }
  .top-image {
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
    width: 100%;
    display: block;
  }
  .video-stacked-wrapper {
    width: 100%;
    position: relative;
    margin-bottom: 30px;
    .side-title {
      font-weight: bold;
      font-size: 17px;
      color: #c01a14;
      position: absolute;
    }
    #side-title-1 {
      top: 13%;
      #{$ldirection}: 9%;
    }
    #side-title-2 {
      top: 48%;
      #{$ldirection}: 9%;
      width: 100px;
    }
    #side-title-3 {
      top: 73%;
      #{$rdirection}: 4%;
    }
    .video-container {
      width: 100%;
      position: relative;
      .video-play {
        width: auto;
        height: auto;
        @include swap_direction(border, 0 none);
      }
      .video-title-link {
        position: absolute;
        bottom: 5px;
        color: #fff;
        text-decoration: underline;
        font-weight: bold;
      }
    }
    #video-container-1 {
      z-index: 20;
      margin-top: -30px;
      #video-thumbnail-1 {
        width: 52%;
        margin-#{$ldirection}: 45%;
      }
      .video-title-link {
        #{$ldirection}: 48%;
      }
    }
    #video-local-1,
    #video-local-2,
    #video-local-3,
    #video-local-4,
    #video-local-5 {
      position: absolute;
      #{$ldirection}: 0;
      z-index: -1;
      width: 0;
      height: 0;
      line-height: 0;
      background-color: red;
    }
    #video-container-2 {
      z-index: 10;
      margin-top: -15px;
      #video-thumbnail-2 {
        width: 61%;
        margin-#{$ldirection}: 0;
      }
      .video-title-link {
        #{$ldirection}: 3%;
      }
    }
    #video-container-3 {
      z-index: 20;
      margin-top: 10px;
      #video-thumbnail-3 {
        width: 45%;
        margin-#{$ldirection}: 55%;
      }
      .video-title-link {
        #{$ldirection}: 58%;
      }
    }
    #video-container-4 {
      z-index: 10;
      margin-top: -90px;
      #video-thumbnail-4 {
        width: 52%;
        margin-#{$ldirection}: 5%;
      }
      .video-title-link {
        #{$ldirection}: 8%;
      }
    }
    #video-container-5 {
      margin-top: 10px;
      #video-thumbnail-5 {
        width: 50%;
        margin-#{$ldirection}: 23%;
      }
      .video-title-link {
        #{$ldirection}: 26%;
      }
    }
  }
}
