$bv-iphone-landscape-range: '#{$screen} and (max-width:#{lower-bound($iphone6-range)})' !default;

@mixin product-section-text {
  font-family: 'AkzidenzGrotesk W1G';
  font-size: 15px;
  line-height: 25px;
  color: $color-navy;
}

.page-product .page-main {
  padding-top: $header-vertical-padding;
}

.spp {
  overflow: hidden;
  padding-bottom: 25px;
}

// spp product
.spp-product {
  @include pie-clearfix;
  @include text-product-anchor;
  margin-bottom: 25px;
  .slick-slider {
    margin-top: 10px;
  }
  #{$estee_edit} & {
    font: 14px $ffmarkweb;
  }
}

.spp-product__misc-flag {
  font-size: 12px;
  font-family: 'AkzidenzGrotesk W1G';
  letter-spacing: 0.15em;
  text-transform: uppercase;
  margin-bottom: 3px;
  #{$renutriv} & {
    font-family: $roboto-condensed;
  }
  #{$estee_edit} & {
    font-family: $ffmarkweb;
    margin-bottom: 5px;
    letter-spacing: 0;
  }
}

.product-full_pre-order-text {
  display: none;
}

.spp-product__header {
  @include headline--tertiary;
  font-family: $optimaregular;
  line-height: 1;  
  #{$renutriv} & {
    font-family: $bb-roman;
    text-transform: uppercase;
    letter-spacing: normal;
  }
  #{$estee_edit} & {
    font: 22px/24px $ffmarkweb-medium;
    text-transform: uppercase;
    letter-spacing: normal;
    margin-bottom: 0;
  }
  span {
    display: inline-block;
  }
}

.spp-product__sub-header {
  @include headline--quaternary;
  #{$renutriv} & {
    font: 28px $helvetica-neue;
  }
  #{$estee_edit} & {
    font: 22px/24px $ffmarkweb;
    text-transform: uppercase;
    margin-bottom: 6px;
  }
}

.spp-product__image-container {
  width: 100%;
  height: 254px;
  position: relative;
  margin-bottom: 12px;
  overflow: hidden;
  #{$estee_edit} & {
    height: 395px;
  }
  .slick-dots {
    z-index: 2;
    bottom: 22px;
  }
  #{$estee_edit} & {
    bottom: 50px;
    height: 73px;
  }
}

.spp-product__images {
  width: 100%;
  height: 250px;
  img {
    display: block;
  }
}

.spp-product__image:nth-child(n + 2) {
  background: $color-white;
}

.spp-product__image {
  height: 250px;
  width: 100%;
  background: url(/media/export/cms/products/bg/product_bg_250px.jpg) repeat-x 0 0 $color-light-gray;
  #{$renutriv} & {
    background: black;
  }
  #{$estee_edit} & {
    background: url(/media/export/cms/products/bg/product_bg_tee_250px.jpg) repeat-x 0 0
      $color-white;
  }
  #{$aerin} & {
    background: none;
  }
  a.video-launcher {
    display: block;
    width: 100%;
  }
  img {
    height: 250px;
    width: auto;
    @include swap_direction(margin, 0 auto);
  }
  &:nth-child(1) {
    background: none;
  }
  &.smoosh {
    background: $color-white;
    img {
      @include swap_direction(padding, 53px 0);
    }
  }
}

.spp-product__description {
  @include swap_direction(margin, 0 $mobile-outer-gutter);
  .product__inventory-status {
    li {
      @include swap_direction(margin, 10px 0);
    }
  }
  #{$estee_edit} & {
    text-align: center;
    .BVRROverallRatingContainer,
    .BVRRRatingSummaryLinks {
      display: inline-block;
      vertical-align: top;
      float: none;
    }
  }
}

.spp-product__review {
  @include text--short;
  margin-top: 20px;
}

.spp-product__reviews-image {
  margin-bottom: 2px;
}

.spp-product__review-write {
  @include link--bold;
  #{$renutriv} & {
    font-family: $roboto-condensed;
  }
}

.spp-product__attribute {
  margin-top: 15px;
  #{$estee_edit} & {
    margin-top: 0;
  }
}

.spp-product__attribute h3 {
  @include text--bold;
  margin-bottom: 0px;
  #{$renutriv} & {
    font-family: $roboto-condensed;
    font-size: 16px;
  }
}

.spp-product__attribute p {
  @include text--short;
  margin-top: 5px;
  #{$renutriv} & {
    font-family: $roboto-condensed;
    text-transform: none;
    letter-spacing: normal;
    font-size: 16px;
  }
  #{$estee_edit} & {
    font: 22px/26px $devinne-roman;
    text-transform: none;
    letter-spacing: normal;
    padding: 0;
    margin: 0 0 12px 0;
  }
}

.spp-product__price-size {
  @include swap_direction(padding, 15px 0);
  #{$estee_edit} & {
    font-family: $ffmarkweb-medium;
  }
}

.spp-product__price {
  font-weight: bold;
}

.spp-product__sku-menu {
  display: block;
  margin-top: 10px;
}

.spp-product__price-size-select {
  margin-top: 10px;
  width: 100%;
}

.spp-product__price-size-select {
  &.reference {
    display: none;
  }
}

.shade_select_error_message {
  padding-bottom: 20px;
}

.spp-product__shade-select {
  width: 100%;
  .selectBox-label {
    @include text--bold;
    width: auto;
  }
}

.spp-product__skintype-select {
  margin-top: 10px;
  width: 100%;
}

.custom-select-clone {
  position: relative;
  .filtered-swatch {
    @include swap_direction(margin, 11px 10px 0 0);
    width: 16px;
    height: 16px;
    border-radius: 8px;
    display: block;
    float: $ldirection;
  }
}

.custom-select-current {
  background-image: none;
  &.clicked {
    border: 1px solid $color-black;
    border-bottom: 0px;
  }
  &.custom-select-top.clicked {
    border-bottom: 1px solid $color-black;
    .selectBox-arrow {
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
  .selectBox-label {
    @include swap_direction(padding, 0 20px 0 0);
  }
}

.custom-select-options {
  position: relative;
  .custom-select-box {
    width: 100%;
    position: absolute;
    z-index: 99999;
    min-height: 1em;
    border-style: solid;
    border-width: 1px;
    border-color: $color-black;
    background: $color-white;
    max-height: 210px;
    overflow-x: hidden;
    overflow-y: scroll;
    border-top: 0px;
    &.custom-select-top {
      border-top: 1px solid;
      border-bottom: 0px;
    }
    .custom-select-option {
      @include swap_direction(padding, 0 25px 0 40px);
      font-size: 12px;
      font-family: $akzidenz;
      letter-spacing: 0.15em;
      text-transform: uppercase;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0;
      background-color: $color-white;
      color: $color-darker-gray;
      line-height: 38px;
      height: 40px;
      box-shadow: none;
      text-decoration: none;
      text-align: #{$ldirection};
      position: relative;
      outline: 0;
      z-index: 2;
      vertical-align: middle;
      cursor: default;
      overflow: hidden;
      &.active {
        color: $color-black;
      }
      .filtered-swatch {
        @include swap_direction(margin, 7px 10px 0 -25px);
      }
    }
  }
}

.filter-coral_to_red {
  background: $color-shade-coralred;
  border-#{$rdirection}: 8px solid $color-shade-redcoral;
}
// gold to green
.filter-gold_to_green {
  background: $color-shade-goldgreen;
  border-#{$rdirection}: 8px solid $color-shade-greengold;
}
// mauve to blue
.filter-mauve_to_blue {
  background: $color-shade-mauveblue;
  border-#{$rdirection}: 8px solid $color-shade-bluemauve;
}
// mauve to plum
.filter-mauve_to_plum {
  background: $color-shade-mauveplum;
  border-#{$rdirection}: 8px solid $color-shade-plummauve;
}
// nude to brown
.filter-nude_to_brown {
  background: $color-shade-nudebrown;
  border-#{$rdirection}: 8px solid $color-shade-brownnude;
}
// pink to berry
.filter-pink_to_berry {
  background: $color-shade-pinkberry;
  border-#{$rdirection}: 8px solid $color-shade-berrypink;
}

// INTENSITY
.filter-light {
  background: $color-shade-light-light;
  border-#{$rdirection}: 8px solid $color-shade-light-dark;
}

.filter-light-medium {
  background: $color-shade-lightmedium-light;
  border-#{$rdirection}: 8px solid $color-shade-lightmedium-dark;
}

.filter-medium {
  background: $color-shade-medium-light;
  border-#{$rdirection}: 8px solid $color-shade-medium-dark;
}

.filter-medium-deep {
  background: $color-shade-mediumdeep-light;
  border-#{$rdirection}: 8px solid $color-shade-mediumdeep-dark;
}

.filter-deep {
  background: $color-shade-deep-light;
  border-#{$rdirection}: 8px solid $color-shade-deep-dark;
}

.spp-product__quantity {
  width: 100%;
  margin-top: 10px;
}

.spp-product-full__shade-filter {
  width: 100%;
  color: $color-black;
  option:not(:checked) {
    color: $color-light-gray;
  }
  option:hover {
    background-color: $color-white;
    color: $color-black;
    box-shadow: 0 0 10px 100px $color-white inset;
  }
  .spp-product-full__shade-filter-item:hover {
    background-color: $color-white;
    color: $color-black;
    box-shadow: 0 0 10px 100px $color-white inset;
  }
}
// Replenishment
.product-replenishment-select__info {
  text-transform: uppercase;
  margin: 6px 0;
}

.product-replenishment-select__label {
  text-transform: uppercase;
  font-weight: normal;
  white-space: nowrap;
  padding-right: 1em;
}

.product-replenishment-select__more {
  text-decoration: underline;
  font-weight: bold;
}

.product-replenishment-select__item {
  text-transform: uppercase;
}

.product-replenishment-select__select.selectBox {
  border-color: black;
  width: 100%;
}

.spp-product__add-button {
  display: block;
  @include swap_direction(border, 0);
  width: 100%;
  height: 45px;
  line-height: 43px;
  margin-top: 10px;
}

.spp__phone-button {
  margin-top: 10px;
  height: 45px;
  line-height: 43px;
  width: 100%;
  #{$estee_edit} & {
    background: $color-white;
    color: $color-black;
    border-color: $color-black;
  }
}

.spp__phone-button-icon {
  background-size: auto auto;
  background-position: 0 -6901px;
  display: inline-block;
  height: 18px;
  line-height: 18px;
  padding-#{$ldirection}: 20px;
  margin-top: 12px;
  #{$renutriv} & {
    background: image-url('../themes/estee_base/img/sprites/order_phone_at2x--white.png') no-repeat;
    background-size: contain;
  }
}

.spp-product__personal {
  padding-top: 18px;
  @include swap_direction(margin, 0 $mobile-outer-gutter);
  @include clearfix();
  .spp-product__share {
    margin-#{$rdirection}: 10px;
  }
  #{$estee_edit} & {
    text-align: center;
  }
}

.spp-product__detail-link {
  @include inline-block;
  @include link--bold;
  margin-bottom: 10px;
  #{$estee_edit} & {
    font-family: $ffmarkweb-medium;
  }
}

.spp-product__share,
.spp-product__wishlist {
  @include link--bold;
  float: $ldirection;
  &:first-child {
    margin-#{$ldirection}: 0;
  }
  #{$estee_edit} & {
    font-family: $ffmarkweb;
    float: none;
  }
}

// additional sections

.spp-product__details-header,
.spp-product__section-header {
  @include headline--quinary;
  @include swap_direction(padding, 0);
  @include swap_direction(margin, 0 0 10px 0);
  line-height: 45px;
  #{$estee_edit} & {
    font-family: $ffmarkweb-medium;
    text-transform: uppercase;
    font-size: 18px;
  }
}

.spp-product__additional-section {
  position: relative;
  @include swap_direction(padding, 0 $mobile-outer-gutter);
  border-top: 1px solid $color-light-gray;
  #{$aerin} & {
    font-family: $akzidenz;
    line-height: 25px;
  }
  #{$estee_edit} & {
    font-family: $ffmarkweb;
    p {
      font-family: $ffmarkweb;
      b {
        font-family: $ffmarkweb-medium;
      }
    }
  }
  &.is_closed {
    height: 45px;
    overflow: hidden;
    .spp-product__additional-control {
      background-size: auto auto;
      background-position: 0 -10582px;
      height: 45px;
      width: 21px;
      #{$renutriv} & {
        background-size: auto auto;
        background-position: 0 -10512px;
        height: 45px;
        width: 21px;
      }
    }
  }
  &:link {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .spp-howtouse__instructions {
    line-height: 24px;
  }
  .spp-howtouse,
  .spp-ingredients {
    .spp-howtouse__section1,
    .spp-ingredients__section {
      .spp-howtouse__header.headline--secondary,
      .spp-ingredients__header.headline--secondary {
        @include swap_direction(margin, 0);
        border-bottom: 0;
        font-size: 30px;
        span {
          @include swap_direction(padding, 0);
          @include swap_direction(margin, 0 0 10px 0);
          line-height: 45px;
          font-size: 21px;
          font-family: $optimaregular;
          letter-spacing: 0;
          font-weight: normal;
          color: #040a2b;
          #{$estee_edit} & {
            font-family: $ffmarkweb-medium;
            text-transform: uppercase;
            font-size: 18px;
          }
        }
      }
      .spp-ingredients__disclaimer {
        font-style: italic;
        padding-top: 0.75em;
        padding-bottom: 0.75em;
      }
    }
  }
  .spp-howtouse__section2 {
    .spp-howtouse__header.headline--secondary {
      @include swap_direction(margin, 0);
      border-bottom: 0;
      @include swap_direction(padding, 0);
      @include swap_direction(margin, 0 0 10px 0);
      line-height: 45px;
      font-size: 21px;
      font-family: $optimaregular;
      letter-spacing: 0;
      font-weight: normal;
      color: #040a2b;
      #{$estee_edit} & {
        font-family: $ffmarkweb-medium;
        font-size: 18px;
      }
    }
  }
}

.spp-product__additional-section-button {
  position: absolute;
  height: 45px;
  width: 100%;
  background: transparent;
  z-index: 10;
}

.spp-product__additional-control {
  position: absolute;
  top: 0;
  #{$rdirection}: $mobile-outer-gutter;
  background-size: auto auto;
  background-position: 0 -10212px;
  height: 45px;
  width: 21px;
  #{$renutriv} & {
    background-size: auto auto;
    background-position: 0 -10142px;
    height: 45px;
    width: 21px;
  }
}

.spp-product__section {
  width: 100%;
  @include product-section-text;
  #{$renutriv} & {
    color: white;
    font-family: $roboto-condensed;
  }
  #{$aerin} & {
    color: $color-periwinkle-blue--aerin;
  }
}

// product details
.spp-product__details-container {
  @include swap_direction(padding, 0 $mobile-outer-gutter);
  border-top: 1px solid $color-light-gray;
}

.spp-product__details {
  width: 100%;
  @include product-section-text;
  #{$renutriv} & {
    color: white;
  }
  #{$aerin} & {
    color: $color-periwinkle-blue--aerin;
  }
  b {
    font-size: 14px;
  }
  .spp-product__details-description {
    margin-bottom: 20px;
    #{$estee_edit} & {
      font-family: $ffmarkweb;
      p {
        font-family: $ffmarkweb;
      }
      b {
        font-family: $ffmarkweb-medium;
      }
    }
  }
  h3 {
    @include text--bold;
  }
  ul li {
    list-style: list-item;
    list-style-type: disc;
    list-style-position: inside;
  }
  &-ingredients {
    &-attribute__label {
      @include text--bold;
      margin: 10px 0;
      #{$renutriv} & {
        font-family: $roboto-condensed;
        font-size: 16px;
      }
    }
    &-toggle {
      display: inline-block;
      height: 11px;
      width: 11px;
    }
    &-more {
      background-image: url('/media/export/cms_2.0/SPP/ingredient/product_details_open.png');
    }
    &-less {
      background-image: url('/media/export/cms_2.0/SPP/ingredient/product_details_close.png');
    }
  }
}

.smoosh-list {
  width: 100%;
  height: 144px;
  overflow: hidden;
  .slick-prev,
  .slick-next {
    width: 15px;
    height: 20px;
    &:before {
      content: '';
    }
  }
  .slick-prev {
    #{$ldirection}: 0;
    background-size: auto auto;
    background-position: 0 -8775px;
    height: 20px;
    width: 15px;
    z-index: 999;
    #{$renutriv} & {
      background-size: auto auto;
      background-position: 0 -8885px;
      height: 20px;
      width: 15px;
    }
  }
  .slick-next {
    #{$rdirection}: 0;
    background-size: auto auto;
    background-position: 0 -8555px;
    height: 20px;
    width: 15px;
    #{$renutriv} & {
      background-size: auto auto;
      background-position: 0 -8665px;
      height: 20px;
      width: 15px;
    }
  }
  .smoosh-list-item {
    width: 100%;
    img {
      width: 198px;
      height: auto;
      @include swap_direction(margin, 0 auto);
    }
  }
}

.spp-product__reviews--mobile {
  @include swap_direction(margin, 0 $mobile-outer-gutter);
  @media #{$iphone6plus-portrait} {
    .BVCustomSortWrapper,
    .BVRRDisplayContent {
      margin-#{$ldirection}: -25px;
      margin-#{$rdirection}: -25px;
      padding-#{$ldirection}: 25px;
      padding-#{$rdirection}: 25px;
      padding-top: 30px;
      background: #f6f6f6;
      .BVCustomTitleWrapper {
        width: 100%;
        @include swap_direction(margin, 0 auto);
        padding-bottom: 10px;
        text-align: center;
        position: relative;
      }
      .BVRRDisplayContentSort {
        position: static;
        text-align: $ldirection;
        bottom: 5px;
        #{$rdirection}: 0;
      }
    }
  }
}

.spp-product__reviews-header--mobile {
  @include headline--quinary;
  @include swap_direction(padding, 0 $mobile-outer-gutter);
  @include swap_direction(margin, 0 0 10px 0);
  line-height: 45px;
  border-top: 1px solid $color-light-gray;
  #{$estee_edit} & {
    font-family: $ffmarkweb-medium;
    text-transform: uppercase;
    font-size: 18px;
  }
}

.spp-howtouse__product .js-add-to-cart.quickshop__add-button.button.cta.button--dark {
  padding-#{$ldirection}: 18px;
  padding-#{$rdirection}: 18px;
}
/* EDD */
.free_standard_shipping_returns {
  font-family: 'AkzidenzGrotesk W1G';
  font-size: 10px !important;
  font-weight: bold;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #040a2b;
  @include swap_direction(padding, 13px 0 8px 0);
  #{$estee_edit} & {
    font-family: $ffmarkweb-medium;
    font-size: 12px !important;
  }
}

.edd {
  .show_more {
    text-decoration: underline;
  }
  &.hide {
    visibility: hidden;
  }
  .edd_info {
    .know_more {
      text-decoration: underline;
    }
    p {
      @include swap_direction(margin, 0);
      font-size: 14px;
      line-height: 15px;
      display: inline;
      color: #040a2b;
    }
  }
}

#{$renutriv} .page-main {
  p.copy,
  .free_standard_shipping_returns {
    color: white;
    font-family: $roboto-condensed;
  }
  .free_standard_shipping_returns {
    font-size: 12px;
  }
}

.colorbox_edd_know_more {
  .content {
    h2 {
      font-size: 18px;
      line-height: 1.8;
      margin-bottom: 20px;
      letter-spacing: -0.02em;
    }
  }
}

@media only screen and (orientation: landscape) {
  .free_standard_shipping_returns {
    font-size: 12px;
  }
}
/* END OF EDD */

/* SPP EDD POPUP */

.order_arrival_details_overlay,
.edd .show_more {
  display: none;
}

.order_arrival {
  #order_arrival_info {
    @include swap_direction(margin, 0);
    a {
      text-decoration: underline;
    }
  }
}

.order_arrival_details {
  p {
    font-size: 13px;
    @include swap_direction(margin, 0);
    line-height: 18px;
  }
  .title {
    @include swap_direction(margin, 4px 0 30px 0);
    font-size: 18px;
    font-family: $optimalight;
  }
  .postal_code {
    @include swap_direction(margin, 30px 0 30px 0);
    input {
      width: 60%;
      height: 45px;
      float: $ldirection;
      color: #000;
      font-weight: bold;
      margin-#{$rdirection}: 10px;
    }
    .apply {
      height: 45px;
      line-height: 45px;
      width: 35%;
    }
  }
  .header {
    text-transform: uppercase;
    border-bottom: 1px solid #e6e6e6;
    font-weight: bold;
    height: 25px;
    font-size: 13px;
  }
  .order_delivery_info {
    margin-bottom: 30px;
  }
  .order_delivery_info .shipment_type {
    float: $ldirection;
    width: 44%;
  }
  .order_delivery_info .arrival_date {
    float: $ldirection;
    width: 56%;
    text-align: $rdirection;
  }
  .body {
    .row {
      border-bottom: 1px solid #e6e6e6;
      height: 30px;
      line-height: 30px;
      font-size: 13px;
    }
  }
  .order_delivery_info .ship_us_territory,
  .order_delivery_info .ship_us_mil_pobox,
  .order_delivery_info.valid_zipcode .ship_us_std,
  .order_delivery_info .ship_us_std_exact {
    display: none;
  }
  .order_delivery_info.valid_zipcode .ship_us_std_exact {
    display: block;
  }
  .invalid_zipcode {
    color: #b50404;
    margin-top: 15px;
  }
}

#spp-howtouse-formatter-mobile-steps {
  margin-#{$ldirection}: 25px;
  margin-#{$rdirection}: 25px;
}

.spp-howtouse-steps-mobile-formatter {
  .spp-howtouse-steps-mobile-wrapper {
    margin-#{$ldirection}: 25px;
    margin-#{$rdirection}: 25px;
  }
  #spp-howtouse-headline {
    font-size: 35px;
    line-height: 40px;
    font-family: $optimalight;
  }
  .spp-howtouse-steps-mobile {
    img {
      width: 100%;
      margin-top: 30px;
    }
    .step-headline {
      font-size: 35px;
      line-height: 40px;
      font-family: $optimalight;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}
/* END OF SPP EDD POPUP */

// Mobile SPP GWP message

.spp-gwp__message {
  font-size: 12px;
  letter-spacing: 0.15em;
  line-height: 1em;
  @include swap_direction(padding, 1em 0);
  text-align: center;
  text-transform: uppercase;
  #{$estee_edit} & {
    font-family: $ffmarkweb;
  }
}

.spp-gwp__link {
  text-decoration: underline;
}

.spp-gwp--hide-aerin {
  #{$aerin} & {
    display: none;
  }
}

.spp-gwp--hide-renutriv {
  #{$renutriv} & {
    display: none;
  }
}

//Best For Reviews
.spp-product__reviews--mobile {
  .bv_best_for_container {
    padding-bottom: 50px;
  }
  .bv_best_for_header {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.15em;
    @include swap_direction(padding, 0 20px);
    margin-bottom: 5px;
    text-transform: uppercase;
  }
  .bv_best_for_container li {
    font-family: $optimalight;
    font-size: 70px;
    letter-spacing: -0.04em;
    display: inline;
    padding-#{$rdirection}: 3px;
    line-height: 1;
  }
  .bv_best_for_container li:last-child .reviw_data_comma {
    display: none;
  }
  @media #{$bv-iphone-landscape-range} {
    .bv_best_for_header {
      font-weight: normal;
    }
    .bv_best_for_container li {
      font-size: 21px;
    }
    .BVRRQuickTakeSummary {
      .BVRRRatingSummaryLinks {
        .BVRRRatingSummaryLinkWrite a {
          &:link,
          &:visited,
          &:active,
          &:hover {
            height: auto;
            line-height: 0.9;
          }
        }
      }
    }
  }
}

.product {
  &__price {
    &--non-sale {
      text-decoration: line-through;
      margin-right: 5px;
      font-weight: bold;
      .estee_edit_custom & {
        font-weight: normal;
      }
    }
    &--sale {
      color: $color-red;
      font-weight: bold;
      display: inline-block;
      margin-right: 5px;
      .estee_edit_custom & {
        font-weight: normal;
      }
    }
    &--discount-sale {
      font-weight: bold;
    }
  }
}

.selectBox-label.option-discount-sale {
  letter-spacing: 1px;
  padding: 0 5px;
}

.spp-product__mini-bag-price-size .product__price--sale {
  display: inline-block;
}

.quickshop-inline__price-size-select-container,
.spp-product__price-size-select-container {
  position: relative;
}

.select-markdown {
  width: 100%;
  margin-top: 10px;
  & + .quickshop-inline__price-size,
  & + .spp-product__price-size-select {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.quickshop-inline {
  .quickshop-inline__price-size {
    padding-top: 10px;
  }
}

.quickshop-inline .select-markdown {
  margin-top: 4px;
}

.colorbox__spp-video #cboxContent {
  height: 100% !important;
}

.device-mobile .colorbox__spp-video#colorbox #cboxClose {
  right: 6px;
  top: 4px;
}
/* Engraving Styles Overlay */
.product-full__engraving-button {
  margin-top: 10px;
  width: 100%;
  height: 45px;
  line-height: 45px;
  .engraving {
    display: inline-block;
  }
  .monogramming {
    display: none;
  }
  #{$aerin} & {
    .engraving {
      display: none;
    }
    .monogramming {
      display: block;
    }
  }
}
//Placeholder mixin for Engraving Overlay
@mixin placeholder-engraving {
  font-size: 12px;
  font-family: $akzidenz;
  color: $color-navy;
  line-height: 30px;
  letter-spacing: 0.15em;
  #{$aerin} & {
    font-size: 12px;
    font-family: $akzidenz;
    color: $color-periwinkle-blue--aerin;
    letter-spacing: 0.15em;
    text-indent: 0;
  }
}

.engraving-preview-popup {
  #cboxContent {
    @include swap_direction(padding, 0);
  }
  #cboxClose,
  #cboxClose:hover {
    #{$rdirection}: 10px !important;
    top: 10px !important;
  }
  #cboxClose {
    @include svg-icon-inline-mask('close.svg');
    background: $color-navy;
  }
}

.device-mobile {
  .colorbox__youtube {
    #cboxContent {
      #cboxClose,
      #cboxClose:hover {
        @include svg-icon-inline-mask('close.svg');
        background: $color-white;
        #{$rdirection}: 25px;
        top: 25px;
      }
    }
  }
}

.spp-engraving {
  overflow: hidden;
  position: relative;
  .monogramming,
  .engraving {
    &.button {
      display: none;
    }
  }
  &__engraving-tab {
    display: none;
  }
  #{$aerin} & {
    .engraving {
      display: none;
    }
    .monogramming {
      display: block;
    }
  }
  .error_messages_display {
    @include swap_direction(padding, 0);
    @include swap_direction(margin, 10px 0);
    font-size: 12px;
    color: $color-red;
    text-align: #{$ldirection};
  }
  &__engraving--forms {
    text-align: center;
    @include swap_direction(padding, 45px 21px 25px 15px);
  }
  &__engraving--form {
    text-align: #{$ldirection};
  }
  &__engraving--form-heading {
    > h1 {
      font-size: 55px;
      margin-bottom: 30px;
      text-transform: capitalize;
      letter-spacing: 0;
      line-height: 0.8;
      #{$aerin} & {
        font-family: $futura_now_reg;
        @include swap_direction(padding, 3px 0 0 3px);
        letter-spacing: -0.04em;
        font-size: 40px;
        margin-bottom: 20px;
      }
    }
  }
  &__engraving--form-font-choice-btn {
    margin-bottom: 10px;
    .popupOptima,
    .popupRoman,
    .popupScript,
    .engrave-choice-script {
      display: none !important;
    }
    .change-font {
      background: $color-white;
      @include swap_direction(padding, 0);
      color: $color-navy;
      border: 1px solid $color-light-gray;
      text-transform: capitalize;
      font-size: 14px;
      height: 40px;
      line-height: 37px;
      @include swap_direction(margin, 0 1%);
      width: 31%;
      letter-spacing: -0.05em;
      &:nth-child(1),
      &:nth-child(3),
      &:nth-child(7) {
        @include swap_direction(margin, 0);
      }
      &:nth-child(2) {
        @include swap_direction(margin, 0 3.5%);
      }
      &.engrave-choice-script {
        font-size: 29px;
        margin-#{$ldirection}: 0px;
        line-height: 38px;
        letter-spacing: -0.03em;
        text-indent: -6px;
      }
      &.engrave-style-chosen {
        border: 2px solid $color-navy;
        #{$aerin} & {
          border: 2px solid $color-periwinkle-blue--aerin;
        }
      }
      &.engraving-roman {
        text-transform: uppercase;
      }
      form.engraving-form-choice-1 &,
      form.engraving-form-choice-41 & {
        &.monogramming {
          display: inline-block;
        }
        .button-copy-capitalise {
          &.monogram-romams,
          &.monogram-script {
            vertical-align: top;
          }
          &.monogram-romams {
            font-size: 17px;
          }
          &.monogram-script {
            font-size: 20px;
            text-transform: uppercase;
          }
        }
        &.engrave-choice-roman,
        &.monogram-choice-lower {
          @include swap_direction(margin, 0 2.66666667% 0 0);
          letter-spacing: 0;
        }
        &.engrave-choice-roman {
          font-family: $roman-l-monog-abc;
          font-size: 13px;
          -webkit-text-stroke: 0.5px $color-black;
        }
        &.engrave-choice-roman,
        &.engrave-choice-script {
          font-weight: normal;
          letter-spacing: 0.05em;
          width: 23%;
        }
        &.engrave-choice-roman {
          letter-spacing: 0.1em;
        }
      }
    }
  }
  &__engraving--form-line {
    position: relative;
    margin-bottom: 25px;
    #{$aerin} & {
      margin-bottom: 16px;
    }
    .char-counter {
      color: $color-light-gray;
      position: absolute;
      #{$rdirection}: 10px;
      top: 10px;
    }
    .engraving-message {
      background: none;
      border: 1px solid $color-light-gray;
      display: block;
      color: $color-navy;
      font-size: 16px;
      height: 40px;
      line-height: 32px;
      @include swap_direction(padding, 0 45px 0px 10px);
      text-transform: none;
      width: 100%;
      box-shadow: none;
    }
  }
  &__engraving--form-preview {
    visibility: hidden;
    height: 0;
    &.active {
      height: auto;
      visibility: visible;
    }
    .overlay-left-arrow-nav {
      position: absolute;
      top: 10px;
      #{$ldirection}: 4px;
      a {
        height: 22px;
        display: inline-block;
        background-size: auto auto;
        background-position: 0 -70px;
        background-image: $sprites-sprites;
        background-repeat: no-repeat;
        text-indent: -9999em;
      }
    }
    .spp-engraving__engraving--form-heading {
      h1 {
        margin-bottom: 0;
      }
    }
    .prod-img-wrap {
      height: 250px;
      width: 250px;
      margin: 0 auto;
      position: relative;
      text-align: center;
      overflow: hidden;
      img {
        height: 290px;
        form.engraving-form-choice-1 &,
        form.engraving-form-choice-41 & {
          height: 270px;
        }
        form.engraving-form-choice-7 &,
        form.engraving-form-choice-8 &,
        form.engraving-form-choice-16 & {
          height: 290px;
        }
        @include swap_direction(margin, 0 auto);
      }
      form.engraving-form-choice-46 & {
        height: 280px;
      }
      form.engraving-form-choice-8 & {
        height: 290px;
      }
    }
    .engrave-placement {
      color: $color-white;
      display: block;
      position: relative;
      overflow: hidden;
      &.engrave-choice-roman {
        form.engraving-form-choice-1 & {
          top: -95px;
        }
      }
      &.engrave-choice-script {
        form.engraving-form-choice-1 & {
          top: -117px;
        }
        form.engraving-form-choice-1.small-container & {
          top: -110px;
        }
      }
      form.engraving-form-choice-1 & {
        top: -100px;
        #{$ldirection}: 4px;
      }
      form.engraving-form-choice-41 & {
        top: -90px;
      }
      form.engraving-form-choice-6 & {
        top: -152px;
        left: 5px;
      }
      form.engraving-form-choice-2 &,
      form.engraving-form-choice-4 &,
      form.engraving-form-choice-43 & {
        top: -150px;
        left: -3px;
      }
      form.engraving-form-choice-5 &,
      form.engraving-form-choice-3 & {
        top: -150px;
        left: -3px;
      }
      form.engraving-form-choice-7 &,
      form.engraving-form-choice-8 &,
      form.engraving-form-choice-16 &,
      form.engraving-form-choice-42 & {
        position: absolute;
        top: -32px;
        #{$rdirection}: 0;
        bottom: 0;
        #{$ldirection}: 43px;
        width: 80px;
        height: 182px;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        input {
          color: $color-white;
        }
      }
      form.engraving-form-choice-8 &,
      form.engraving-form-choice-42 & {
        top: 1px;
        #{$ldirection}: 26px;
        width: 99px;
      }
      form.engraving-form-choice-16 & {
        top: -7px;
        #{$ldirection}: 27px;
      }
      form.engraving-form-choice-17 &,
      form.engraving-form-choice-40 & {
        top: -183px;
        #{$ldirection}: 0;
      }
      form.engraving-form-choice-36 & {
        top: -140px;
        width: 100%;
        text-align: center;
        .preview-copy {
          color: $color-gold;
          font-size: 24px;
          letter-spacing: 0.4em;
          text-transform: uppercase;
        }
      }
      form.engraving-form-choice-46 & {
        #{$ldirection}: 77px;
        top: -120px;
        width: 100%;
        .preview-copy {
          font-size: 12px;
          letter-spacing: 0.1em;
        }
        .engraving-size-1_7 &,
        .engraving-size-50ml & {
          #{$ldirection}: 75px;
          top: -135px;
        }
        .engraving-size-3_9 &,
        .engraving-size-115ml & {
          #{$ldirection}: 80px;
          top: -140px;
          .preview-copy {
            font-size: 14px;
            letter-spacing: 0.2em;
          }
        }
      }
      .canvas-orig {
        background: none;
        border: none;
        height: 100%;
        width: 100%;
        color: $color-gold-engrave;
        font-size: 14px;
        letter-spacing: normal;
        white-space: nowrap;
        @include swap_direction(padding, 0);
        text-align: center;
        opacity: 0;
        -webkit-text-stroke: 1px $color-gold-engrave;
        &.engrave-choice-script {
          font-size: 16px;
          #{$aerin} & {
            -webkit-text-stroke: 1px $color-gold-engrave;
            font-size: 22px;
            line-height: 30px;
          }
        }
        &.preview-copy {
          opacity: 1;
          line-height: normal;
          &.engrave-choice-optima {
            font-family: $optima;
          }
          &.engrave-choice-arialosregular {
            font-family: $arialosregular;
          }
          &.engrave-choice-roman {
            font-family: $roman;
            #{$aerin} & {
              font-family: $roman-l-monog-abc;
              line-height: 20px;
            }
          }
          .preview-copy-capitalise {
            #{$aerin} & {
              font-size: 1.3em;
              text-transform: uppercase;
              vertical-align: bottom;
            }
          }
        }
        //Color for Golden Bottle
        &.engraving-golden-font {
          color: $color-bottle-gold;
        }
        #{$aerin} & {
          display: block;
          text-transform: uppercase;
          &.engrave-choice-script {
            .preview-copy-capitalise {
              #{$aerin} & {
                font-size: 1.3em;
              }
            }
          }
        }
      }
      form.engraving-form-choice-6 & {
        .canvas-orig {
          color: $color-navy;
          -webkit-text-stroke: 0.5px $color-navy;
        }
      }
      form.engraving-form-choice-7 & {
        .canvas-orig {
          font-size: 20px;
          position: relative;
          height: 20px;
          width: auto;
          &.large-font {
            font-size: 14px;
          }
        }
      }
      form.engraving-form-choice-8 &,
      form.engraving-form-choice-42 & {
        .canvas-orig {
          font-size: 20px;
          position: relative;
          height: 20px;
          width: auto;
          &.large-font {
            font-size: 14px;
          }
        }
      }
      form.engraving-form-choice-8 & {
        #{$ldirection}: 11px;
        top: 14px;
        .canvas-orig {
          color: $color-white;
          display: inline-block;
          -webkit-text-stroke: 1px $color-white;
          vertical-align: middle;
          &.large-font {
            font-size: 13px;
            margin-top: 5px;
          }
        }
        input.canvas-orig {
          display: block;
        }
      }
      form.engraving-form-choice-16 & {
        .canvas-orig {
          font-size: 18px;
          position: relative;
          height: 20px;
          color: $color-gold-engrave-dark;
          -webkit-text-stroke: 1px $color-gold-engrave-dark;
          width: auto;
          &.large-font {
            font-size: 14px;
          }
        }
      }
    }
  }
  &__engraving--form-element-subtotaling {
    font-family: $akzidenz;
    font-size: 12px;
    margin-bottom: 9px;
    margin-top: 10px;
    text-align: #{$ldirection};
    #{$aerin} & {
      padding-#{$ldirection}: 3px;
    }
    li {
      margin-bottom: 9px;
      font-size: 11px;
      letter-spacing: 0;
      #{$aerin} & {
        margin-bottom: 8px;
      }
      &.engraving-wrap {
        margin-bottom: 13px;
      }
      &.subtotal-wrap {
        margin-bottom: 16px;
      }
      @include swatches(18px, 9px, 6px, 12px, 3px);
      .swatch__container {
        float: $ldirection;
        @include swap_direction(margin, 0 5px 0 0);
      }
    }
    .name {
      float: #{$ldirection};
      width: 70%;
      #{$aerin} & {
        width: 77%;
      }
      &.engraving-text {
        text-transform: uppercase;
        #{$aerin} & {
          letter-spacing: 0.17em;
        }
      }
      .product-title {
        font-family: $optimaregular;
        font-size: 22px;
        margin-bottom: 6px;
        line-height: 1.15em;
        letter-spacing: 0;
        width: 100%;
        #{$aerin} & {
          font-size: 22px;
          padding-#{$ldirection}: 0;
          font-weight: normal;
          letter-spacing: -0.01em;
          margin-bottom: 2px;
        }
      }
      .product-subtitle {
        font-family: $optimaregular;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 1.15em;
        margin-bottom: 13px;
        width: 100%;
        #{$aerin} & {
          letter-spacing: 0.01em;
          margin-bottom: 13px;
          font-size: 18px;
          font-weight: normal;
        }
      }
      .product-shadeColor {
        border-radius: 11px;
        width: 19px;
        height: 19px;
        float: #{$ldirection};
      }
      .product-shadeName {
        text-transform: uppercase;
        float: #{$ldirection};
        margin-#{$ldirection}: 6px;
        margin-top: 2px;
        letter-spacing: 0.15em;
      }
    }
    .numbers {
      display: block;
      float: #{$ldirection};
      text-align: #{$rdirection};
      width: 30%;
      font-size: 12px;
      letter-spacing: 0.17em;
      &.product-cost {
        margin-top: 8px;
      }
    }
  }
  &__engraving--form-element-preview {
    width: 100%;
  }
  &__engraving--form-element-footer {
    font-family: $akzidenz;
    font-size: 10px;
    text-align: #{$ldirection};
    #{$aerin} & {
      font-family: $futura_now_reg;
      font-size: 12px;
    }
    .engraving-cancel {
      display: none;
    }
    .engraving-save {
      @include swap_direction(margin, 0 0 53px 5px);
      letter-spacing: 0.15em;
      #{$aerin} & {
        font-family: $futura_now_md;
        font-size: 12px;
        letter-spacing: 0.22em;
        color: $color-white;
        background: $color-periwinkle-blue--aerin;
        @include swap_direction(margin, 0 0 19px 3px);
        @include swap_direction(padding, 0 19px 0 23px);
      }
    }
    .engraving-msg {
      font-size: 15px;
      line-height: 15px;
      clear: both;
      letter-spacing: 0.05em;
      #{$aerin} & {
        font-size: 14px;
        font-family: $akzidenz;
        letter-spacing: 0;
        line-height: normal;
      }
    }
  }
  .engrave-choice-arialosregular,
  .engraving-arialosregular {
    font-family: $arialosregular;
    font-size: 16px;
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-text-stroke: 1px $color-black;
  }
  .engrave-choice-optima,
  .engraving-optima {
    font-family: $optimalight;
    font-size: 20px;
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-text-stroke: 1px $color-black;
  }
  .engrave-choice-roman,
  .engraving-roman {
    font-family: $roman;
    font-size: 18px;
    #{$aerin} & {
      font-family: $roman-l-monog-abc;
    }
    &.engraving-message {
      font-family: $roman;
      #{$aerin} & {
        font-family: $roman-l-monog-abc;
        font-size: 18px;
      }
    }
  }
  .engrave-choice-script,
  .engraving-script {
    font-family: $script;
    font-size: 18px;
    letter-spacing: 0;
    #{$aerin} & {
      font-family: $interlock-m-script-abc;
      font-size: 28px;
      text-indent: 6px;
      line-height: 34px;
      text-transform: lowercase;
      letter-spacing: 0.08em;
      -webkit-text-stroke: 1px $color-black;
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        -webkit-text-stroke: 0px $color-black;
        line-height: 42px;
      }
    }
  }
  .small-container {
    .engrave-choice-script {
      #{$aerin} & {
        font-size: 28px;
        line-height: 40px;
      }
    }
    .engrave-choice-roman {
      #{$aerin} & {
        font-size: 18px;
        line-height: 40px;
      }
    }
    input:placeholder-shown {
      font-size: 12px !important;
      line-height: 12px !important;
      text-indent: 0;
    }
  }
  .engraving-save {
    display: none;
  }
  .engraving-save,
  .engraving-preview {
    @include swap_direction(margin, 5px 0 15px 0);
    height: 40px;
    line-height: 40px;
    width: 100%;
  }
  .engrave-choice-block {
    background: $color-white;
  }
  .engraving-form-custom-msg {
    &.engraving {
      display: none;
    }
    #{$aerin} & {
      line-height: 19px;
      @include swap_direction(padding, 2px 0 0 5px);
      margin-bottom: 7px;
    }
  }
  .engraving-message {
    #{$aerin} & {
      text-transform: inherit;
    }
    &::placeholder {
      @include placeholder-engraving;
    }
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      @include placeholder-engraving;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      @include placeholder-engraving;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      @include placeholder-engraving;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      @include placeholder-engraving;
    }
  }
  #{$aerin} & {
    font-family: $futura_now_reg;
  }
  &__engraving--promo-sku {
    .spp-engraving__engraving--form-font-choice-btn {
      display: none;
    }
    .spp-engraving__engraving--form-preview {
      .engrave-placement {
        .canvas-orig {
          &.preview-copy {
            &.engrave-choice-roman {
              font-family: $roman-l-monog-abc;
            }
          }
        }
      }
    }
    .prod-img-wrap img {
      height: 270px;
    }
    .engrave-choice-roman {
      &.engraving-message {
        font-family: $roman-l-monog-abc;
      }
    }
  }
}

.section-product {
  .spp-engraving {
    &__engraving--form-heading {
      &.engraving {
        display: none;
      }
      &.monogramming {
        display: block;
      }
    }
    &__engraving-tab--item {
      display: none;
    }
    &__engraving--form-font-choice-btn {
      .engraving,
      .engraving-script {
        &.button {
          display: none !important;
        }
      }
    }
  }
  .engraving-wrap {
    .engraving {
      display: none;
    }
    .monogramming {
      display: block;
    }
  }
}
/* View cart page - Engraving */
.engraving-view {
  border-top: 1px solid $color-light-gray;
  @include swap_direction(margin, 0);
  @include swap_direction(padding, 12px 5px 0 6px);
  &__name {
    color: $color-navy;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    float: #{$ldirection};
  }
  &__price {
    font-size: 12px;
    font-weight: bold;
    float: #{$rdirection};
    letter-spacing: 0.15em;
  }
  &__message {
    background: $color-gray-cs-side;
    @include swap_direction(padding, 0px 5px);
    margin-top: 11px;
    letter-spacing: 0.04em;
    &-content {
      float: #{$ldirection};
      font-size: 23px;
      font-weight: normal;
      font-family: $optimaregular;
      @include fontname--Monog-Engr;
      &.Interlock_M_Script_aBc,
      &.Interlock_M_Script_abc,
      &.Optima {
        -webkit-text-stroke: 0.5px $color-black;
      }
    }
    &-cta {
      float: #{$rdirection};
      font-size: 10px;
      letter-spacing: 0.2em;
      padding-top: 7px;
    }
    &-edit,
    &-delete {
      text-decoration: underline;
      text-transform: uppercase;
    }
    &-delete {
      border-#{$ldirection}: 1px solid $color-navy;
      padding-#{$ldirection}: 11px;
      margin-#{$ldirection}: 6px;
    }
    &-disclaimer {
      font-size: 75%;
      margin-top: 6px;
      padding-#{$ldirection}: 1px;
    }
  }
  &__sku--promo {
    .engraving-view__message-delete {
      display: none;
    }
  }
}

.cart-items__engraving-sku-item {
  .cart-item__qty-replenishment-group {
    display: none;
  }
  &.monogram-product {
    .monogramming {
      display: inline-block;
    }
    .engraving {
      display: none;
    }
  }
  .engraving {
    display: inline-block;
  }
  .monogramming {
    display: none;
  }
  .engraving-button-container {
    display: none;
  }
}

.engraving-button-container {
  &.monogram-product {
    .monogramming {
      display: inline-block;
    }
    .engraving {
      display: none;
    }
  }
}

.cart-item-table__engravable {
  span {
    @include fontname--Monog-Engr;
  }
}
/* End of Engraving Styles */

.spp-popup-overlay-form__content {
  &.success-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 98%;
  }
  .address-form {
    margin-top: 20px;
  }
}

// Notify Me
.product__button--notify-me {
  width: 100%;
  margin-top: 10px;
  height: 45px;
  line-height: 45px;
  display: none;
  #aerin & {
    color: $color-white;
    line-height: 30px;
  }
}

.basic-reorder__sold-out {
  color: $color-red;
  display: none;
}
