///
/// @file drupal_modules/esteelauder_endeca/_mobile_block_search.scss
///
/// \brief styles for the mobile search block
///
/// used in template:
/// esteelauder_endeca/templates/endeca_search_v1/endeca_search_block_mobile_v1.html
/// and various theme based endeca tpl files
///

.el-mobile-search-block {
  background: $color-navy;
  @include swap_direction(padding, 1em 0);
  width: 100%;
  position: absolute;
  left: 0;
  color: white;
  z-index: 200;
  display: none;

  #{$alt} & {
    #{$renutriv} & {
      background: inherit;
    }

    #{$aerin} & {
      background: inherit;
    }
  }
  #{$alt-color-white} & {
    background: $color-medium-gray-new;
    padding: 0;
  }

  // Hide when sticky nav happens
  #{$sticky}:not(#{$search}) & {
    display: none;
  }
  // #{$sticky}#{$search} & {
  //   display: block;
  // }

  // #{$sticky}#{$search} & {
  //   display: block;
  // }
}

.el-mobile-search-block__content {
  position: relative;
  @include swap_direction(margin, 0 1em);
  #{$alt-color-white} & {
    padding: 1em 0;
  }
}

.el-mobile-search-block__content .el-mobile-search-block__input-container {
  @include swap_direction(margin-bottom, 0);
  .mobile-form-text {
    @include swap_direction(border, none);
    width: 95%;
    height: 34px;
    line-height: 34px;
    @include swap_direction(padding, 0);
    font-family: $optimaregular;
    font-size: 1.6em;
    letter-spacing: 0;
    color: $color-white;
    background: $color-navy;
    text-align: center;
    #{$alt-color-white} & {
      @include input-placeholder {
        background-color: $color-medium-gray-new;
        color: $color-navy;
      }
      @include print-size-tracking(21px, -50);
      background: $color-medium-gray-new;
      text-align: $ldirection;
      color: $color-navy;
      height: 34px;
    }
  }
  input[type='text'] {
    @include input-placeholder {
      color: $color-white;
      background-color: $color-navy;
      text-transform: none;
    }
  }
  .el-mobile-search-close {
    background-size: auto auto;
    background: url('/media/images/global/sprites-s38a9885fce.png') 0 -742px no-repeat;
    position: absolute;
    #{$rdirection}: 0;
    width: 28px;
    height: 28px;
    #{$alt-color-white} & {
      background-image: url('/media/export/cms/gnav/search_close_x_black.png');
      background-size: auto;
      background-position: center;
      width: 22px;
      height: 22px;
      margin-top: 5px;
    }
  }
}

.el-mobile-search-block__btn-submit {
  display: block;
  line-height: 34px;
  cursor: pointer;
  position: absolute;
  #{$rdirection}: 14px;
  top: 50%;
  margin-top: (-0.5 * image-height('../themes/estee_base/img/sprites/search_blue.png'));
  &,
  #{$aerin} #{$alt} &,
  #{$renutriv} #{$alt} & {
    background-image: url('/media/images/background-images/background-image17.png');
    background-size: 21px 22px;
    width: 21px;
    height: 22px;
  }
  #{$aerin} & {
    background-image: url('/media/images/background-images/background-image18.png');
    background-size: 21px 22px;
    width: 21px;
    height: 22px;
  }
  #{$renutriv} & {
    background-image: url('/media/images/background-images/background-image19.png');
    background-size: 21px 22px;
    width: 21px;
    height: 22px;
  }
}

.no-backgroundsize .el-mobile-search-block__btn-submit,
.no-backgroundsize .brand-aerin .is-alternate .el-mobile-search-block__btn-submit,
.no-backgroundsize .brand-renutriv .is-alternate .el-mobile-search-block__btn-submit {
  background-image: url($base-theme-image-path + 'sprites/search_blue.png');
  width: 21px;
  height: 22px;
}

.no-backgroundsize .brand-aerin .el-mobile-search-block__btn-submit {
  background-image: url($base-theme-image-path + 'sprites/search_periwinkle.png');
  width: 21px;
  height: 22px;
}

.no-backgroundsize .brand-renutriv .el-mobile-search-block__btn-submit {
  background-image: url($base-theme-image-path + 'sprites/search_black.png');
  width: 21px;
  height: 22px;
}

///
/// typeahead
///

.typeahead-wrapper {
  @include pie-clearfix;
  text-align: $ldirection;
  margin-top: 1em;
  width: 100%;
  color: $color-white;
  background: $color-navy;
  //position: absolute;
  z-index: 1000;
  padding-bottom: 20px;
  #{$alt-color-white} & {
    background: $color-white;
    color: $color-navy;
    margin-top: 0;
    border-bottom: 1px solid $color-medium-gray-new;
  }
  .typeahead-product-results {
  }
  .product-result {
    clear: both;
    @include swap_direction(padding, 15px 7.8125% 20px 7.8125%);
    border-bottom: 1px solid $color-medium-navy;
    #{$alt-color-white} & {
      border-bottom: 1px solid $color-medium-gray-new;
    }
  }
  .product-result__content {
    @include pie-clearfix;
  }
  .product-result__image {
    float: $ldirection;
    width: 56px;
    height: 82px;
    overflow: hidden;
    background-color: $color-light-gray;
  }
  .product-result__info {
    float: $ldirection;
    margin-#{$ldirection}: 15px;
    width: 70%;
    a {
      font-family: $optimaregular;
      color: $color-periwinkle-blue;
      text-decoration: none;
      #{$alt-color-white} & {
        color: $color-navy;
        font-weight: 700;
      }
    }
  }
  .product-result__name {
    a {
      font-size: 21px;
    }
  }
  .product-result__sub-name {
    margin-top: 6px;
    a {
      font-size: 15px;
    }
  }
  .no-results {
    #{$alt-color-white} & {
      background: $color-white;
      color: $color-navy;
    }
  }
}

.typeahead-summary {
  position: relative;
  text-transform: uppercase;
  @include swap_direction(padding, 15px 7.8125%);
  border-top: 1px solid $color-medium-navy;
  border-bottom: 1px solid $color-medium-navy;
  @include text--short;
  #{$alt-color-white} & {
    border-top: 1px solid $color-medium-gray-new;
    border-bottom: 1px solid $color-medium-gray-new;
  }
  p {
    @include swap_direction(margin, 0);
  }
  .has-results {
    @include pie-clearfix;
    line-height: 14px;
    .has-results__text {
      float: $ldirection;
    }
    .no-results__additional {
      display: none;
    }
    .typeahead__close {
      @include squish-text;
      background-size: auto auto;
      background-position: 5px -11973px;
      position: absolute;
      top: 0;
      #{$rdirection}: 0;
      @include swap_direction(padding, 5px 7.8125% 5px 14px);
      cursor: pointer;
      // width: 9px;
      // height: 14px;
      height: 24px;
      display: none;
    }
  }
}

.typeahead-see-results {
  @include swap_direction(margin, 25px 7.8125% 0 7.8125%);
  a {
    @include text--bold;
    @include swap_direction(border, 1px solid $color-periwinkle-blue);
    display: block;
    color: $color-periwinkle-blue;
    text-decoration: none;
    text-align: center;
    height: 46px;
    line-height: 44px;
    #{$alt-color-white} & {
      color: $color-white;
      background-color: $color-navy;
      border-color: $color-navy;
    }
  }
}
