///
/// @file drupal_modules/esteelauder_homepage/_home_product__mobile_v1.scss
///
/// \brief Styling for template field module at:
///    esteelauder_homepage/templates/home_product_mobile_v1
///

%mobile-copy {
  width: 50%;
  text-align: center;
  float: $rdirection;
}

// Shared
.homepage-product-mobile {
  position: relative;
  img {
    width: 100%;
    text-align: center;
  }
  .headline--large {
    font-size: 80px;
    text-align: center;
    line-height: 62px;
    letter-spacing: 0;
    position: relative;
    z-index: 3;
  }
  .headline--medium {
    font-size: 60px;
    text-align: center;
    line-height: 42px;
    letter-spacing: 0;
    position: relative;
    z-index: 3;
  }
}

// Call to action buttons
.tout-cta-button-wrapper--mobile {
  width: 100%;
  text-align: center;
  @include swap_direction(padding, 0 25px);
  .cta {
    display: block;
    @media #{$landscape} {
      display: inline-block;
    }
  }
}
///
/// Mobile home  A
///
.homepage-product-mobile__content--a {
  margin-top: 60px;
  margin-bottom: 60px;
  min-height: 400px;
  position: relative;
  div.button-explore-wrapper--mobile {
    position: absolute;
    bottom: -15px;
  }
  .tout-cta-button-wrapper--mobile {
    position: absolute;
    bottom: 50px;
    #{$ldirection}: 0;
  }
  .headline--large {
    margin-bottom: -24px;
  }
}

.homepage-product-mobile__promo--a {
  .text-promo {
    @extend %mobile-copy;
    @include swap_direction(padding, 40px 10px 10px);
  }
}
// mobile home a image
.homepage-product-mobile__hero--a {
  display: block;
  position: absolute;
  z-index: -1;
  top: 45px;
  #{$ldirection}: 0;
}

///
/// Mobile home B
///
.homepage-product-mobile__content--b {
  margin-top: 40px;
  min-height: 350px;
  position: relative;
  .headline--large {
    @include swap_direction(margin, 0 0 -12px);
  }
}

.homepage-product-mobile__promo--b {
  .text-promo {
    @include swap_direction(margin, 20px auto 20px);
    text-align: center;
    @include swap_direction(padding, 10px);
  }
}

.product-promo-image--b {
  width: 210px;
  @include swap_direction(margin, 0 auto);
  position: relative;
  z-index: 1;
}
// mobile home b image
.homepage-product-mobile__hero--b {
  display: block;
  position: absolute;
  z-index: -1;
  top: 0;
  #{$ldirection}: 0;
}

// explore button
.button-explore-wrapper--mobile {
  width: 100%;
  margin-top: 25px;
  .button-explore-label {
    @include button-plus;
    width: 270px;
    background-size: auto auto;
    background-position: 60px -3276px;
    text-transform: uppercase;
    text-indent: 0;
    @include swap_direction(padding, 0 0 0 110px);
    @include swap_direction(margin, 0 auto);
    font-size: 12px;
    font-family: $akzidenz;
    font-weight: bold;
    color: $color-navy;
  }
  .button-explore-no-label {
    @include button-plus;
    @include swap_direction(margin, 0 auto);
  }
}

.header_gifty_content {
  .headline--medium {
    margin-top: 15px;
  }
  .disclaimer-description {
    text-align: center;
    width: 74%;
    @include swap_direction(margin, 7px auto);
  }
  .homepage-product-mobile__content--c {
    margin-top: 0;
  }
  .homepage-product-mobile__promo--c {
    .text-promo {
      margin: 0;
      .subhead {
        font-weight: bold;
      }
      .subcopy {
        width: 65%;
        @include swap_direction(margin, 0 auto);
      }
    }
  }
}
