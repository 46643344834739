.product-brief {
  position: relative;
  float: $ldirection;
  text-align: center;
  color: $color-navy;
  width: 37.5%;
  padding-bottom: 52px; // offset for button
  #{$renutriv} & {
    color: white;
  }
  #{$estee_edit} & {
    color: $color-black;
  }
  #{$aerin} & {
    color: $color-periwinkle-blue--aerin;
  }
  // estee edit feature product styles
  .mpp__product__feature.white & {
    color: $color-white;
  }
}

.product-brief__image {
  @include swap_direction(margin, 0 2.5% 14px 2.5%);
  display: block;
  background: #eaebef;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% top;
  width: 95%;
  padding-bottom: 143.5%;
  height: 155px;
}

.product-brief__image-wrapper {
  @include swap_direction(margin, 0 2.5% 14px 2.5%);
  display: block;
  position: relative;
  width: 95%;
  overflow: hidden;
  padding-bottom: 12px;
  background: #eaebef;
  & img {
    width: 100%;
    height: auto;
    display: block;
  }
}

.product-brief__misc-flag,
.product-brief__misc-flag-spacer {
  font-size: 12px;
  font-family: 'AkzidenzGrotesk W1G';
  letter-spacing: 0.15em;
  text-transform: uppercase;
  margin-bottom: 3px;
  #{$renutriv} &, .brand-style-renutriv & {
    font-family: $roboto-condensed;
  }
  #{$aerin} & {
    font-size: 12px;
  }
  #{$estee_edit} & {
    font-size: 11px;
    font-family: $ffmarkweb;
    letter-spacing: 0;
    color: $color-black;
  }
  // estee edit feature product styles
  .mpp__product__feature.white & {
    color: $color-white;
  }
}

.product-brief__header {
  @include headline--quinary;
  #{$renutriv} & {
    font-family: $bb-roman;
    text-transform: uppercase;
  }
  #{$estee_edit} & {
    font-family: $ffmarkweb-medium;
    text-transform: uppercase;
    font-size: 15px;
    color: $color-black;
    min-height: 30px;
  }
  // estee edit feature product styles
  .mpp__product__feature.white & {
    color: $color-white;
  }
}

.product-brief__sub-header {
  @include headline--senary;
  #{$renutriv} & {
    font-family: $roboto-condensed;
  }
  .estee_edit_custom & {
    font-family: $ffmarkweb-medium;
    text-transform: uppercase;
    font-size: 11px;
    color: $color-black;
  }
  // estee edit feature product styles
  .mpp__product__feature.white & {
    color: $color-white;
  }
}

.product_brief__reviews {
  font-size: 12px;
  line-height: 16px;
  height: 16px;
  margin-top: 5px;
}

.product_brief__reviews-image {
  margin-bottom: 2px;
}

.product-brief__button-quickshop,
.product-brief__button-quickshop--close {
  position: absolute;
  bottom: 0;
  #{$ldirection}: 0;
  height: 45px;
  line-height: 43px;
  @include swap_direction(padding, 0);
  @media #{$iphone6-portrait} {
    width: 100%;
  }
  @media #{$iphone6-landscape} {
    width: 100%;
  }
  @media #{$iphone6plus-portrait} {
    width: 100%;
  }
  @media #{$iphone6plus-landscape} {
    width: 100%;
  }
  @media #{$small-only} {
    width: 100%;
  }
  padding: 0;
  width: 100%;
  @include swap_direction(padding, 0);
  text-align: center;
  margin-top: 7px;
  text-decoration: none;
  #{$renutriv} & {
    color: $color-gold--renutriv;
    text-transform: uppercase;
    font-family: $roboto-condensed;
    font-size: 14px;
  }
}

.product-brief__button-quickshop {
  #{$estee_edit} & {
    max-width: 140px;
    padding: 0 5px;
    position: relative;
  }
}

.product-brief__price {
  #{$estee_edit} & {
    margin-top: 7px;
    font-family: $ffmarkweb-medium;
    letter-spacing: 0;
  }
}
//Estee Edit MPP custom grid video btns
.quickshop__with_video {
  #{$estee_edit} & {
    width: 100%;
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    a.video-play {
      position: relative;
      padding: 0 22px;
      background: url('/media/export/cms/mpp/mobile-play-btn2.gif') no-repeat center center transparent;
      background-size: 45px 45px;
      bottom: 0;
      right: 0;
      height: 45px;
      width: 45px;
      float: right;
      margin-top: 7px;
      margin-left: 5px;
      @media #{$iphone6-portrait} {
        margin-left: 10px;
      }
      @media #{$iphone6-landscape} {
        margin-left: 10px;
      }
      @media #{$iphone6plus-portrait} {
        margin-left: 10px;
      }
      @media #{$iphone6plus-landscape} {
        margin-left: 10px;
      }
      display: block;
      border: none;
    }
  }
}

.product-brief__button-quickshop--close {
  @include link--bold;
  background-size: auto auto;
  background-position: 100% -7258px;
  @include swap_direction(border, solid 1px $color-light-gray);
  text-indent: -20px;
  #{$renutriv} &,
  #{$aerin} & {
    background-size: auto auto;
    background-position: 100% -7258px;
  }
  #{$estee_edit} & {
    background-size: auto auto;
    background-position: 100% -7326px;
    background-color: $color-white;
    color: #0012c9;
    font-family: $ffmarkweb;
    letter-spacing: 0;
  }
}

.product-brief__quickshop-container {
  @include box-shadow(inset 0px 8px 4px -4px rgba(0, 0, 0, 0.3));
  position: relative;
  display: none;
  clear: both;
  width: 100%;
  overflow: hidden;
  #{$renutriv} & {
    @include box-shadow(inset 0px 8px 4px -4px rgba(255, 255, 255, 0.3));
  }
}

.product__inventory-status {
  li {
    margin-top: 10px;
    color: $color-red;
    display: none;
    text-transform: uppercase;
  }
  .pre-order-container & {
    display: none !important; // to override JS inline style
  }
}

.product-full_pre-order-text {
  display: none;
}

.pre-order_status_display {
  margin: 10px 0;
  font-size: 15px;
  #vb_container & {
    color: $color-white;
  }
}

#vb_container {
  .extended-mpp {
    .mpp__product-grid {
      .product__inventory-status {
        margin-top: 10px;
      }
    }
  }
}

.pre-order-exposed-container {
  .pre-order_status_display {
    font-size: 10px;
    text-transform: uppercase;
    .pre-order_active_msg {
      display: none;
    }
  }
}

// Notify Me
.mpp {
  .basic-reorder__sold-out {
    margin-top: 10px;
  }
}

.page-product.device-mobile {
  #colorbox.add_cart_response {
    height: 190px !important; // !important is used to override inline and base styles written for the popup.
    #cboxClose,
    #cboxClose:hover {
      background: url('/media/images/global/sprites-s38a9885fce.png') no-repeat 0 -973px;
      height: 28px;
      #{$rdirection}: 10px;
      top: 10px;
      width: 28px;
    }
  }
}
