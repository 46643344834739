$ff-background-color: #fae7da;

.ff-quiz {
  background: $ff-background-color;
}

.ff-quiz {
  @include swap_direction(padding, 25px 0 0 0);
}

.ff-quiz__title {
  text-align: center;
  @include swap_direction(margin, 0 0 12px 0);
  line-height: 0.95;
  @include swap_direction(padding, 0 25px);
}

.ff-quiz__subhead {
  text-align: center;
  text-transform: uppercase;
  @include swap_direction(margin, 0 0 20px 0);
  font-size: 12px;
  letter-spacing: 0.1em;
  @include swap_direction(padding, 0 25px);
  > strong {
    display: block;
    @include swap_direction(margin, 0 0 6px 0);
  }
}

.ff-quiz__nav-item {
  @include swap_direction(padding, 15px $mobile-outer-gutter);
  border-top: 1px solid #afa198;
  border-bottom: 1px solid #afa198;
  margin-top: -1px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.1em;
  background: url('/media/export/cms/foundation-finder/ff-icon-sprite.png') no-repeat right 12px;
}

.ff-quiz__nav-text {
  @include pie-clearfix;
  @include opacity(0.5);
  @include transition(opacity 0.2s ease-in-out);
}

.ff-quiz__nav-label {
  float: $ldirection;
  display: block;
  width: 115px;
}

.ff-quiz__nav-value {
  float: $ldirection;
  display: block;
  letter-spacing: 0.15em;
  font-weight: bold;
}

.ff-quiz__slide.active .ff-quiz__nav-item {
  cursor: default;
  background-position: right -68px;
  .ff-quiz__nav-text {
    @include opacity(1);
  }
}

.ff-quiz__nav-item.selected:hover {
  .ff-quiz__nav-text {
    @include opacity(0.7);
  }
}

.ff-quiz__slide.active .ff-quiz__nav-item:hover {
  cursor: default;
  .ff-quiz__nav-text {
    @include opacity(1);
  }
}

.ff-quiz__nav-item.selected {
  cursor: pointer;
}

.ff-quiz__slide--content {
}

.ff-quiz__slide--padding {
  @include swap_direction(padding, 35px 20px 70px 20px);
  border-bottom: 1px solid #afa198;
  background: $ff-background-color;
  position: relative;
  overflow: hidden;
}

.ff-quiz__headline {
  font-size: 35px;
  line-height: 35px;
  text-align: center;
  margin-bottom: 25px;
}

.ff-quiz__headline--secondary {
  font-family: $optimalight;
  letter-spacing: -0.01em;
  color: $color-navy;
  font-size: 16px;
  line-height: 17px;
}

.ff-quiz__headline--tertiary {
  font-family: $akzidenz;
  letter-spacing: 0.15em;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  @include swap_direction(margin, 0 0 8px 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ff-quiz__description {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  @include swap_direction(margin, 5px 0 0 0);
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 1;
}

.ff-quiz__slide--footer {
  position: absolute;
  #{$ldirection}: 0;
  bottom: -1px;
  width: 100%;
  text-align: center;
}

.ff-quiz__button--next {
  background: $color-navy url('/media/export/cms/foundation-finder/next-button__arrow-down.png') no-repeat 50% -9px;
  color: #fff;
  height: 50px;
  width: 80px;
  padding-top: 8px;
  text-align: center;
  text-align: center;
  display: block;
  position: relative;
  @include opacity(0);
  visibility: hidden;
  @include swap_direction(margin, 0 auto);
  font-family: $optimaregular;
  font-size: 14px;
  letter-spacing: 1px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include transition(opacity 0.2s ease-in-out);
  &.active {
    @include opacity(1);
    visibility: visible;
  }
}

.ff-quiz__question {
  @include pie-clearfix;
  @include swap_direction(margin, 0);
  > li {
    float: $ldirection;
    width: 33.3%;
    text-align: center;
  }
}

.ff-quiz__answer {
  display: block;
  @include opacity(0.5);
  @include transition(opacity 0.2s ease-in);
  outline: none;
  &:hover {
    @include opacity(0.7);
  }
}

.ff-quiz__checkbox {
  display: block;
  @include swap_direction(margin, 0 auto 10px auto);
  background: url('/media/export/cms/foundation-finder/checkbox.png') no-repeat 0 -30px;
  background-position: 0 -20px;
  background-size: 100%;
  width: 20px;
  height: 20px;
}

.ff-quiz__answer.active {
  @include opacity(1);
  .ff-quiz__checkbox {
    background-position: 0 0;
  }
}

.ff-quiz__slider {
  @include swap_direction(margin, 0 auto 15px auto);
  width: 100%;
  //width: 83%;
  position: relative;
  z-index: 11;
}

.ff-quiz__coverage-stage,
.ff-quiz__intensity-stage {
  position: relative;
  //max-width: 580px;
  height: 155px;
  @include swap_direction(margin, 0 auto);
  text-align: center;
}

.ff-quiz__intensity-stage {
  height: 165px;
  z-index: 0;
}

.ff-quiz__coverage-image,
.ff-quiz__intensity-image {
  position: absolute;
  width: 100%;
  height: 135px;
  background: $ff-background-color;
  color: $color-navy;
  font-family: $optimalight;
  font-size: 90px;
  line-height: 100px;
  letter-spacing: -0.05em;
  @include swap_direction(margin, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.ff-quiz__coverage-image--slide,
  &.ff-quiz__intensity-image--slide {
    @include opacity(0.2);
    line-height: 140px;
  }
  &.ff-quiz__intensity-image--01,
  &.ff-quiz__intensity-image--02,
  &.ff-quiz__intensity-image--03,
  &.ff-quiz__intensity-image--04,
  &.ff-quiz__intensity-image--05,
  &.ff-quiz__intensity-image--06,
  &.ff-quiz__intensity-image--07,
  &.ff-quiz__intensity-image--08,
  &.ff-quiz__coverage-image--sheer,
  &.ff-quiz__coverage-image--medium,
  &.ff-quiz__coverage-image--full,
  &.ff-quiz__intensity-image--very-light,
  &.ff-quiz__intensity-image--light,
  &.ff-quiz__intensity-image--light-medium,
  &.ff-quiz__intensity-image--medium,
  &.ff-quiz__intensity-image--medium-deep,
  &.ff-quiz__intensity-image--deep,
  &.ff-quiz__intensity-image--very-deep {
    display: none;
  }
}

.ff-quiz__intensity-image {
  height: 145px;
}

.ff-quiz__coverage-image--thumb-wrapper,
.ff-quiz__intensity-image--thumb-wrapper {
  position: absolute;
  top: 0;
  #{$ldirection}: 0;
  text-align: center;
  z-index: 1;
  width: 100%;
}

.ff-quiz__coverage-image--thumb,
.ff-quiz__intensity-image--thumb {
  max-width: 300px;
  @include swap_direction(margin, 0 auto);
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  #{$ldirection}: 20px;
  top: 5px;
}

.ff-quiz__intensity-image--thumb {
  #{$ldirection}: -15px;
  top: -26px;
  width: 115%;
  max-width: none;
}

.ff-quiz__slide-image {
  text-align: center;
}

.ff-quiz__slide-image--fluid {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.noUi-horizontal .noUi-handle {
  #{$ldirection}: -10px;
  top: -7px;
  height: 20px;
  width: 20px;
}

.noUi-handle {
  @include swap_direction(border, 2px solid $color-white);
}

///
/// Slide Variations
///

.ff-quiz__slide--1,
.ff-quiz__slide--4 {
  .ff-quiz__headline {
    margin-bottom: 7px;
  }
}

.ff-quiz__slide--1 {
  .ff-quiz__slide--padding {
    padding-bottom: 35px;
  }
  .ff-quiz__question {
    @include swap_direction(padding, 0);
    @include swap_direction(margin, 0 auto);
    position: relative;
    text-align: center;
    top: -30px;
    width: 90%;
    > li {
      width: 33.3%;
      padding-top: 35px;
      background: url('/media/export/cms/foundation-finder/sliderui-marker.png') no-repeat 50% 0;
    }
  }
}

.ff-quiz__slide--2 {
  .ff-quiz__slide--padding {
    padding-bottom: 125px;
  }
  .ff-quiz__question {
    margin-bottom: 10px;
  }
  .ff-quiz__slide-image {
    position: absolute;
    //bottom: -45px;
  }
}

.ff-quiz__slide--4 {
  .noUi-base {
    width: 92%;
  }
  .noUi-horizontal .noUi-handle {
    #{$ldirection}: -10px;
  }
  .ff-quiz__slide--padding {
    padding-#{$ldirection}: 0;
    padding-#{$rdirection}: 0;
    padding-bottom: 35px;
  }
  .ff-quiz__slider--padding {
    @include swap_direction(padding, 0 20px);
  }
  .ff-quiz__headline {
    margin-bottom: 0;
    position: relative;
    z-index: 1;
    @include swap_direction(padding, 0 20px);
  }
  .ff-quiz__slider {
    width: 100%;
  }
  .ff-quiz__question {
    width: 83%;
    //width: 690px;
    @include swap_direction(padding, 0 20px);
    @include swap_direction(margin, 0 auto);
    position: relative;
    text-align: center;
    top: -30px;
    > li {
      width: 14.28571%;
      @include swap_direction(padding, 35px 5px 0 5px);
      background: url('/media/export/cms/foundation-finder/sliderui-marker.png') no-repeat 50% 0;
    }
  }
  .ff-quiz__answer {
    display: none;
    &.ff-quiz__answer--01,
    &.ff-quiz__answer--08,
    &.ff-quiz__answer--very-light,
    &.ff-quiz__answer--medium,
    &.ff-quiz__answer--very-deep {
      display: block;
    }
    &.ff-quiz__answer--08 .ff-quiz__headline--tertiary {
      position: relative;
      right: 21px;
    }
  }
  .ff-quiz__answer--medium .ff-quiz__headline--tertiary {
    margin-#{$ldirection}: -15px;
    @media (min-width: 500px) {
      margin-#{$ldirection}: 0;
    }
  }
  .ff-quiz__slide--padding.answer_8 .ff-quiz__question > li {
    width: 12.28571%;
  }
  .ff-quiz__slide--padding.answer_8 .noUi-horizontal .noUi-handle {
    #{$ldirection}: -15px;
  }
}

.ff-quiz__slide--5 {
  .ff-quiz__slide--padding {
    border-bottom: 0;
  }
}

.ff-quiz__filter-button-wrapper {
  //position: absolute;
  //#{$ldirection}: 0;
  //bottom: 20px;
  @include swap_direction(padding, 25px 0);
  text-align: center;
  width: 100%;
  display: none;
  .ff-quiz--edit & {
    display: block;
  }
}

// Edit
.ff-quiz--edit {
  margin-bottom: 30px;
  @include box-shadow(rgba(black, 0.4) 0 -10px 10px -12px inset);
  .ff-quiz__slide--footer {
    display: none;
  }
  .ff-quiz__slide--padding {
    padding-bottom: 35px;
  }
  .ff-quiz__slide--2 {
    .ff-quiz__slide--padding {
      padding-bottom: 60px;
    }
  }
}
