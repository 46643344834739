///
/// @file drupal_modules/esteelauder_endeca/_mobile_page_search.scss
///
/// \brief styles for the mobile search page
///
/// used in template:
/// esteelauder_endeca/templates/endeca_search_mobile_v1/
/// and various theme based endeca tpl files
///

// global stuff for search
$mobile-search-outer: 320px;
$mobile-search-inner: 270px;
%mobile-accordian {
  height: 44px;
  line-height: 44px;
}

.mobile-search-page {
  // max-width: $mobile-search-outer;
  // @include swap_direction(margin, 0 auto);
  // color: $color-navy;
  // position: relative;
  // text-align: center;
  position: relative;
  padding-bottom: 20px;
  @include swap_direction(margin, 0 auto);
  width: 100%;
  .mobile-search-summary,
  .mobile-search-heading,
  .search-page__help,
  .pagination {
    text-align: center;
  }
  .product-brief {
    padding-bottom: 65px;
  }
}

.mobile-search-heading {
  text-transform: uppercase;
  @include swap_direction(margin, 1em 0);
  font-family: 'AkzidenzGrotesk W1G';
  font-weight: bold;
  font-size: 0.75em;
}

///
/// summary area
///
.mobile-search-page__loading {
  display: none;
  position: absolute;
  top: 20px;
  #{$ldirection}: 110px;
}

.no-results {
  background: $color-navy;
  color: $color-white;
  text-align: $ldirection;
  @include swap_direction(padding, 1em);
  #did-you-mean {
    a {
      @media #{$small-only} {
        color: $color-white;
      }
    }
  }
}

///
/// filters and refinements
///
.refinements-header {
  display: block;
  background-color: $color-navy;
  color: $color-white;
  @include headline--senary;
  @extend %mobile-accordian;
  cursor: pointer;
  text-align: center;
}

.mobile-search-page__filters {
  @include headline--senary;
  background-color: $color-navy;
  color: $color-white;
  @include swap_direction(margin, 1em auto);
  max-width: $mobile-search-inner;
  text-align: $ldirection;
  a {
    color: $color-periwinkle-blue;
    display: block;
    &:hover {
      color: $color-white;
    }
  }
  .accordion-trigger {
    background-size: auto auto;
    background-position: 0 -6047px;
    height: 44px;
    width: 44px;
    display: block;
    float: $rdirection;
    @extend %mobile-accordian;
  }
  .close-x {
    background-size: auto auto;
    background-position: 0 -6415px;
    height: 44px;
    width: 44px;
    display: inline-block;
    float: $rdirection;
    //line-height: 44px;
  }
  dl {
    @include swap_direction(padding, 0);
    text-align: $ldirection;
  }
  dt {
    @extend %mobile-accordian;
    @include swap_direction(padding, 0 0 0 1em);
    border-top: #4f546a solid 1px;
    cursor: pointer;
    &.collapsed {
      color: $color-periwinkle-blue;
      .accordion-trigger {
        background-size: auto auto;
        background-position: 0 -6231px;
        height: 44px;
        width: 44px;
      }
    }
  }
  dd,
  .mobile-breadcrumb {
    @include swap_direction(padding, 0 0 0 1em);
    @include swap_direction(margin, 0);
    background-color: $color-medium-navy-alt;
    border-top: #283054 solid 1px;
    @extend %mobile-accordian;
  }
}

// color family dimensions
.mobile-color-family__swatch {
  width: 44px;
  height: 44px;
  display: block;
  float: $ldirection;
  @include swap_direction(margin, -1px 1em 0 -1em);
}
// @todo: test old ie then apply filter-gradient($start-color, $end-color, $orientation)
// if needed
// coral to red
.refinement-coral_to_red {
  @include filter-gradient(#ee2a25, #ae1123);
  @include background(linear-gradient(left top, #ee2a25 30%, #ae1123 70%));
}
// gold to green
.refinement-gold_to_green {
  @include filter-gradient(#efc8a7, #6e885c);
  @include background(linear-gradient(left top, #efc8a7 30%, #6e885c 70%));
}
// mauve to blue
.refinement-mauve_to_blue {
  @include filter-gradient(#805666, #374b69);
  @include background(linear-gradient(left top, #805666 30%, #374b69 70%));
}
// mauve to plum
.refinement-mauve_to_plum {
  @include filter-gradient(#93004c, #a23d5b);
  @include background(linear-gradient(left top, #93004c 30%, #a23d5b 70%));
}
// nude to brown
.refinement-nude_to_brown {
  @include filter-gradient(#b0625e, #70342f);
  @include background(linear-gradient(left top, #b0625e 30%, #70342f 70%));
}
// pink to berry
.refinement-pink_to_berry {
  @include filter-gradient(#f72f5e, #b3223d);
  @include background(linear-gradient(left top, #f72f5e 30%, #b3223d 70%));
}

.el-mobile-search__product-results {
  position: relative;
  width: 100%;
  .results {
    @include pie-clearfix;
    position: relative;
    @include swap_direction(margin, 0 auto);
    //max-width: $mobile-search-inner;
    width: 100%;
    min-height: 200px;
    &.ready {
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    }
  }
  .result {
    @include swap_direction(margin, 0 4.6875% 33px $mobile-outer-gutter-fluid);
    &.result--second {
      clear: $rdirection;
      @include swap_direction(margin, 0 4.6875% 33px $mobile-outer-gutter-fluid);
    }
    &.product-brief {
      .pre-order_status_display {
        display: none;
      }
      .product__inventory-status {
        display: none !important;
      }
    }
  }
}

// .mpp__product-grid {
//   @include pie-clearfix;
//   @include opacity(0);
//   margin-top: 20px;
//   .mpp__product {
//     @include swap_direction(margin, 0 4.6875% 33px $mobile-outer-gutter);
//     &.mpp__product--second {
//       clear: $rdirection;
//       @include swap_direction(margin, 0 4.6875% 33px $mobile-outer-gutter);
//     }
//   }
// }
// ///
// /// products list returned
// ///
// .el-mobile-search__product-results {
//   position: relative;
//   //max-width: $mobile-search-inner;
//   width: 100%;
//   // .result {
//   //   min-height: 200px;
//   //   width: 120px;
//   //   @include swap_direction(margin, 0 30px 1em 0);
//   //   padding-bottom: 34px;
//   //   position: relative;
//   //   float: $ldirection;
//   //   text-align: center;
//   //   &:nth-child(2n+2) { //clear every row at 2nd product
//   //     clear: $rdirection;
//   //     margin-#{$rdirection}: 0;
//   //   }
//   // }
//   // .product-image {
//   //   width: 113px;
//   //   height: 155px;
//   //   margin-bottom: 10px;
//   //   background: $color-light-gray;
//   //   overflow: hidden;
//   // }
//   .results {
//     @include pie-clearfix;
//     position: relative;
//     @include swap_direction(margin, 0 auto);
//     //max-width: $mobile-search-inner;
//     width: 100%;
//     min-height: 200px;
//   }
//   .result {
//     position: relative;
//     float: $ldirection;
//     text-align: center;
//     color: $color-navy;
//     width: 37.5%;
//     padding-bottom: 52px; // offset for button
//     @include swap_direction(margin, 0 4.6875% 33px $mobile-outer-gutter-fluid);
//     // &:nth-child(2n+2) { //clear every row at 2nd product
//     //   clear: $rdirection;
//     //   margin-#{$rdirection}: 0;
//     // }
//     &.result--second {
//       clear: $rdirection;
//       @include swap_direction(margin, 0 4.6875% 33px $mobile-outer-gutter-fluid);
//     }
//   }
//   .product-image {
//     display: block;
//     background: #eaebef;
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-position: 50% top;
//     width: 95%;
//     padding-bottom: 143.5%;
//     height: 155px;
//     @include swap_direction(margin, 0 2.5%);
//   }
//   .title {
//     @include headline--senary;
//     font-size: 18px;
//   }
//   .details {
//     font-size: 14px;
//   }
//   .search-qs {
//     position: absolute;
//     bottom: 0;
//     @include swap_direction(border, $color-navy solid 1px);
//     display: block;
//     width: 100%;
//     text-transform: uppercase;
//     @include swap_direction(padding, .5em);
//     font-size: .75em;
//     font-weight: bold;
//   }
// }

///
/// pagination
///
.results-header-container {
  position: relative;
}

.button--pager {
  text-decoration: none;
  @include swap_direction(padding, 0.5em);
  @include swap_direction(margin, 0.5em 0);
}

///
/// help block
///
.search-page__help {
  @include swap_direction(padding, 7.8125% 0);
  .search-help__buttons {
    border-bottom: 1px solid $color-light-gray;
    margin-bottom: 1em;
    padding-bottom: 1em;
  }
  .button {
    @include swap_direction(border, $color-navy solid 1px);
    font-weight: bold;
  }
}

.search-help__content {
  text-transform: uppercase;
}

.search__quickshop-container {
  @include box-shadow(inset 0px 8px 4px -4px rgba(0, 0, 0, 0.3));
  position: relative;
  display: none;
  clear: both;
  width: 100%;
  overflow: hidden;
}
