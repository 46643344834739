///
/// @file drupal_modules/esteelauder_common/_video_youtube_mobile.scss
/// Styles for promo item linking to a youtube video
///
/// \brief Styling for template at:
///   esteelauder/modules/custom/esteelauder_common/templates/video_youtube_mobile_v1
///

.video_youtube {
  position: relative;
  @include swap_direction(margin, 0 auto);
}

.video-headline-mobile {
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 2;
}

.video_youtube__content {
  min-height: 119px;
  width: 210px;
  @include swap_direction(margin, 60px auto 1em auto);
  color: #fff;
  position: relative;
  &.color--dark {
    color: #000;
  }
  .video-play {
    position: absolute;
    bottom: 20px;
    #{$ldirection}: 20px;
    z-index: 2;
  }
}

.video-bg-mobile {
  max-width: 100%;
}

.video-play {
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 11px 0 11px 17px;
  border-color: transparent transparent transparent $color-white;
  &.color--dark {
    border-color: transparent transparent transparent $color-navy;
  }
}

// Re-nutrive theme
.video_youtube,
.video_youtube_double {
  &.fonts-theme-regular {
    header {
      .video-headline-mobile {
        font-size: 45px;
        font-family: $optimalight;
        text-transform: uppercase;
        margin-bottom: 15px;
      }
    }
    .text-promo-header,
    .text-promo {
      font-family: $akzidenz;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 1.5em;
      letter-spacing: 0.03em;
    }
  }
  &.fonts-theme-re-nutriv {
    header {
      .video-headline-mobile {
        font-size: 40px;
        font-family: $bb-roman;
        text-transform: uppercase;
      }
    }
    .text-promo-header,
    .text-promo {
      font-family: $roboto-condensed;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 1.5em;
      letter-spacing: 0.03em;
    }
  }
}

.video_youtube {
  &.fonts-theme-regular {
    header {
      .video-headline-mobile {
        top: -34px;
      }
    }
  }
  &.fonts-theme-re-nutriv {
    header {
      .video-headline-mobile {
        top: -34px;
      }
    }
  }
}

// video_youtube_custom_mobile_v1 and video_youtube_custom_mobile_v2 styles
.video_youtube_custom_mobile {
  position: relative;
  @include swap_direction(margin, 0 auto);
  &.fonts-theme-regular {
    header {
      .video-headline-mobile {
        font-size: 47px;
        font-family: $optimalight;
        margin-bottom: -5px;
      }
    }
    .text-promo-header,
    .text-promo {
      font-family: $akzidenz;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 1.5em;
      letter-spacing: 0.03em;
    }
  }
  &.fonts-theme-re-nutriv {
    header {
      .video-headline-mobile {
        font-size: 40px;
        font-family: $bb-roman;
      }
    }
    .text-promo-header,
    .text-promo {
      font-family: $roboto-condensed;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 1.5em;
      letter-spacing: 0.03em;
    }
  }
  .video-headline-mobile {
    position: relative;
    text-align: center;
    width: 100%;
    font-size: 60px;
  }
  .video_youtube__content {
    min-height: 119px;
    width: 100%;
    @include swap_direction(margin, 0);
    color: #fff;
    position: relative;
    &.color--light {
      .video-play {
        border-color: transparent transparent transparent $color-white;
      }
      .video-title span {
        color: $color-white;
      }
    }
    &.color--dark {
      .video-play {
        border-color: transparent transparent transparent $color-navy;
      }
      .video-title span {
        color: $color-navy;
      }
    }
    .video-play {
      position: absolute;
      top: 30%;
      #{$ldirection}: 45%;
      z-index: 2;
    }
    .video-title {
      position: absolute;
      #{$ldirection}: 0;
      bottom: 5px;
      width: 100%;
      text-align: center;
      span {
        display: inline-block;
        font-size: 14px;
        font-family: $akzidenz;
      }
    }
  }
  .video-bg-mobile {
    max-width: 100%;
  }
  .video-play {
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30px 0 30px 50px;
    border-color: transparent transparent transparent $color-white;
  }
  footer {
    padding-top: 20px;
    text-align: center;
    .text-promo-header,
    .text-promo {
      font-size: 14px;
      color: #000;
      display: inline-block;
      width: 100%;
    }
    .text-promo-header {
      font-weight: bold;
    }
    .text-promo {
      font-weight: normal;
    }
  }
}

.video_youtube_custom_mobile.v2 {
  .video_youtube__content {
    .video-eyebrow {
      position: absolute;
      #{$ldirection}: 0;
      bottom: 50px;
      text-align: center;
      width: 100%;
      span {
        display: inline-block;
        font-size: 18px;
        font-family: $akzidenz;
      }
    }
    .video-title {
      position: absolute;
      #{$ldirection}: 0;
      bottom: 5px;
      width: 100%;
      text-align: center;
      span {
        display: inline-block;
        font-size: 45px;
        font-family: $optimalight;
      }
    }
  }
  footer {
    .text-small {
      font-size: 10px;
      color: #000;
      display: inline-block;
      width: 100%;
      margin-top: 20px;
    }
  }
}
