///
/// @file drupal_modules/esteelauder_common/_hero_tout_mobile.scss
/// also use by product_hero_tout_mobile
/// See _home_product_mobile.scss for inspiration and extends
///

.hero-tout-mobile {
  position: relative;
  max-width: 320px;
  @include swap_direction(margin, 0 auto);
  img {
    width: 100%;
    text-align: center;
  }
  .headline--large {
    text-align: center;
    position: relative;
    z-index: 3;
    letter-spacing: -0.05em;
  }
}

.hero-tout-mobile__copy {
  @extend %mobile-copy;
  .subhead {
    font-weight: bold;
  }
}

.hero-tout-mobile__image {
  img {
    bottom: 0;
    top: auto;
  }
}

///
/// Mobile style  A
///
.hero-tout-mobile__content--a {
  margin-top: 60px;
  min-height: 400px;
  position: relative;
  .tout-cta-button-wrapper--mobile {
    //position: absolute;
    float: $rdirection;
    bottom: 50px;
    #{$ldirection}: 0;
    &:nth-of-type(2n + 1) {
      @include swap_direction(margin, 20px 0 20px 0);
    }
  }
  .headline--large {
    font-size: 80px;
    line-height: 62px;
    margin-bottom: -24px;
  }
  &:first-child {
    margin-top: 20px;
  }
}

.hero-tout-mobile__promo--a {
  .text-promo {
    @extend %mobile-copy;
    @include swap_direction(padding, 40px 10px 10px);
  }
}
// mobile home a image
.hero-tout-mobile__hero--a {
  display: block;
  position: absolute;
  z-index: -1;
  top: 40px;
  #{$ldirection}: 0;
}

///
/// Mobile style B
///
.hero-tout-mobile__content--b {
  margin-top: 60px;
  min-height: 350px;
  position: relative;
  text-align: center;
  .headline--large {
    font-size: 70px;
    line-height: 50px;
    @include swap_direction(margin, 0 0 -12px);
  }
  .tout-cta-button-wrapper--mobile {
    &:nth-of-type(2n + 1) {
      @include swap_direction(margin, 20px 0 20px 0);
    }
  }
  &:first-child {
    margin-top: 20px;
  }
}

.hero-tout-mobile__promo--b {
  .text-promo {
    @include swap_direction(margin, 10px auto 10px);
    text-align: center;
    @include swap_direction(padding, 10px);
  }
}

.product-promo-image--b {
  width: 210px;
  @include swap_direction(margin, 0 auto);
  position: relative;
  z-index: 1;
}
// mobile home b image
.hero-tout-mobile__hero--b {
  display: block;
  position: absolute;
  z-index: -1;
  top: 0;
  #{$ldirection}: 0;
}

///
/// Mobile style C
///
.hero-tout-mobile__content--c {
  &:first-child {
    margin-top: 20px;
  }
}
